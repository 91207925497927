import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard-icon.svg'
import { ReactComponent as AnalyticsIcon } from 'assets/icons/analytics.svg'
import { ReactComponent as GameIcon } from 'assets/icons/game.svg'
import { ReactComponent as ActivityIcon } from 'assets/icons/activity-icon.svg'
import { ReactComponent as AssetsIcon } from 'assets/icons/assets-icon.svg'
import { ReactComponent as ConfigIcon } from 'assets/icons/configuration-icon.svg'
import { ReactComponent as LaunchpadIcon } from 'assets/icons/flash-circle.svg'
import { ReactComponent as NotificationIcon } from 'assets/icons/notification.svg'
import { ReactComponent as GamePublisherIcon } from 'assets/icons/user-edit.svg'
import { ReactComponent as GamePlayerIcon } from 'assets/icons/user-octagon.svg'
import { ReactComponent as StarterPackIcon } from 'assets/icons/box.svg'
import { ReactComponent as SubAdminIcon } from 'assets/icons/tag-user.svg'
import { ReactComponent as QuestIcon } from 'assets/icons/quest-icon.svg'
import { ReactComponent as ActivityReportIcon } from 'assets/icons/activity_report.svg'
import { ReactComponent as UserReportIcon } from 'assets/icons/user_report.svg'
import { ReactComponent as BannerIcon } from 'assets/icons/banner.svg'

import {
  activityPath,
  activityReportPath,
  analyticsPath,
  assetsPath,
  configurationPath,
  gamePublisherPath,
  gamesPath,
  launchpadPath,
  notificationsPath,
  playersPath,
  questPath,
  rootPath,
  starterPackPath,
  subAdminPath,
  userReportPath,
  webCarouselPath,
  webFloatingCarouselPath,
} from 'logic/paths'

import React from 'react'
import { CAROUSEL_TYPE } from 'utils/enums'

interface SidebarItem {
  path: string
  label: string
  icon?: React.ReactNode
  isAccordion?: boolean // Optional if you have some items that are accordions
  items?: SidebarItem[] // Optional if you have nested items
}

interface SidebarItemsMap {
  [key: string]: SidebarItem
}

// Define all possible sidebar items in a dictionary for easy access and modification.
const SIDEBAR_ITEMS: SidebarItemsMap = {
  dashboard: {
    path: rootPath,
    icon: <DashboardIcon className='strokeIcon' />,
    label: 'Dashboard',
  },
  analytics: {
    path: analyticsPath,
    icon: <AnalyticsIcon className='strokeIcon' />,
    label: 'Analytics',
  },
  players: {
    path: playersPath,
    icon: <GamePlayerIcon className='strokeIcon' />,
    label: 'Players',
  },
  subAdmin: {
    path: subAdminPath,
    icon: <SubAdminIcon className='strokeIcon' />,
    label: 'Sub Admin',
  },
  games: {
    path: gamesPath,
    icon: <GameIcon className='fillIcon' />,
    label: 'Games',
  },
  assets: {
    path: assetsPath,
    icon: <AssetsIcon className='strokeIcon' />,
    label: 'Assets',
  },
  gamePublisher: {
    path: gamePublisherPath,
    icon: <GamePublisherIcon className='strokeIcon' />,
    label: 'Game Publisher',
  },
  notifications: {
    path: notificationsPath,
    icon: <NotificationIcon className='fillIcon' />,
    label: 'Notifications',
  },
  launchpad: {
    path: launchpadPath,
    icon: <LaunchpadIcon className='strokeIcon' />,
    label: 'Launchpad',
  },
  starterPack: {
    path: starterPackPath,
    icon: <StarterPackIcon className='strokeIcon' />,
    label: 'Starter Pack',
  },
  webCarousel: {
    path: webCarouselPath,
    icon: <BannerIcon className='strokeIcon' />,
    label: 'Web Carousel',
  },
  quest: {
    path: questPath,
    icon: <QuestIcon className='strokeIcon questIcon' />,
    label: 'Quest',
  },
  userReport: {
    path: userReportPath,
    icon: <UserReportIcon className='strokeIcon questIcon' />,
    label: 'User Report',
  },
  activityReport: {
    path: activityReportPath,
    icon: <ActivityReportIcon className='strokeIcon' />,
    label: 'Activity Report',
  },
  activity: {
    path: activityPath,
    icon: <ActivityIcon className='strokeIcon' />,
    label: 'Activity',
  },
  configuration: {
    path: configurationPath,
    icon: <ConfigIcon className='strokeIcon' />,
    label: 'Configuration',
  },

  webCarouselAccordion: {
    label: 'Web Carousel',
    path: webCarouselPath,
    icon: <BannerIcon className='strokeIcon' />,
    isAccordion: true,
    items: [
      {
        path: webCarouselPath,
        label: CAROUSEL_TYPE.GENERAL,
      },
      {
        path: webFloatingCarouselPath,
        label: CAROUSEL_TYPE.FLOATING,
      },
    ],
  },
}

// Define specific sidebar configurations for each role by referencing the shared items.
export const SUPER_ADMIN_SIDEBAR_ITEMS = [
  SIDEBAR_ITEMS.dashboard,
  SIDEBAR_ITEMS.analytics,
  SIDEBAR_ITEMS.players,
  SIDEBAR_ITEMS.subAdmin,
  SIDEBAR_ITEMS.games,
  SIDEBAR_ITEMS.assets,
  SIDEBAR_ITEMS.gamePublisher,
  SIDEBAR_ITEMS.notifications,
  SIDEBAR_ITEMS.launchpad,
  SIDEBAR_ITEMS.starterPack,
  SIDEBAR_ITEMS.webCarouselAccordion,
  SIDEBAR_ITEMS.quest,
  SIDEBAR_ITEMS.userReport,
  SIDEBAR_ITEMS.activityReport,
  SIDEBAR_ITEMS.activity,
  SIDEBAR_ITEMS.configuration,
]

export const SUB_ADMIN_SIDEBAR_ITEMS = [
  SIDEBAR_ITEMS.dashboard,
  SIDEBAR_ITEMS.analytics,
  SIDEBAR_ITEMS.players,
  SIDEBAR_ITEMS.games,
  SIDEBAR_ITEMS.assets,
  SIDEBAR_ITEMS.gamePublisher,
  SIDEBAR_ITEMS.notifications,
  SIDEBAR_ITEMS.launchpad,
  SIDEBAR_ITEMS.starterPack,
  SIDEBAR_ITEMS.webCarouselAccordion,
  SIDEBAR_ITEMS.quest,
  SIDEBAR_ITEMS.userReport,
  SIDEBAR_ITEMS.activityReport,
  SIDEBAR_ITEMS.activity,
  SIDEBAR_ITEMS.configuration,
]

export const PUBLISHER_SIDEBAR_ITEMS = [
  SIDEBAR_ITEMS.quest,
  SIDEBAR_ITEMS.games,
  SIDEBAR_ITEMS.launchpad,
  SIDEBAR_ITEMS.starterPack,
  SIDEBAR_ITEMS.webCarousel,
]
