import EthIcon from 'assets/icons/ethLogo.svg'
import SolIcon from 'assets/icons/solana.svg'
import InvalidImagePlaceholder from 'assets/images/invalid-image-placeholder.png'
import { BigNumber } from 'bignumber.js'
import imageCompression, { Options } from 'browser-image-compression'
import * as chrono from 'chrono-node'
import _ from 'lodash'
import moment, { Moment } from 'moment'
import momentTimezone from 'moment-timezone'
import { BLOCK_EXPLORE_URLS, serverConfig } from './constants'
import {
  ASSET_SOURCE,
  EVENT_SUB_TYPE,
  EVENT_TYPE,
  mediaTypes,
  QUEST_REWARD_TYPES,
  SUPPORTED_CHAIN,
} from './enums'
import { INameDetails, I_RewardsData } from './type'
import { ls } from 'dev/secureLS'
import { UploadImageToS3 } from './apis'
import { PAGE_LIMIT } from 'modules/pages/gamePlayers/constants'
import toast from 'react-hot-toast'

const SHOW_TIMEZONE = true // Set this to false to hide the timezone

export type UrlPath<T> = string & { _?: T } // Basically a string.  The second clause is to peg the generic type

export const intNumRegex = /^[0-9]+$/ // check if input is number (decimal not allowed)
export const positiveIntNumRegex = /^(0|[1-9][0-9]*)$/
export function matchesPath(
  path: string,
  value: string,
  exact: boolean
): boolean {
  const generalized = path.replace(/:\w+/g, '[^/]*?')
  const pattern = `^${generalized}${exact ? '$' : ''}`
  const regex = new RegExp(pattern)
  return !!value.match(regex)
}

export const getLocalJwt = () => {
  return ls.get('userAccessToken') ?? sessionStorage.getItem('userAccessToken')
}

export const urlValidationRegEx =
  /^((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
export const floatNumRegex = /^(?!.*\..*\.)[.\d]+$/ // check if input is number (decimal number allowed)

export const handleImageError = (
  event: React.SyntheticEvent<HTMLImageElement, Event>
) => {
  const target = event.target as HTMLImageElement
  target.src = InvalidImagePlaceholder // Set alternative image source
}

export const formattedDate = (dateString: any) => {
  if (!dateString) return 'N/A'

  // Base format for the date
  let formatString = 'MMMM DD, YYYY [at] h:mm a'

  const userTimezone = momentTimezone.tz.guess() // Detect the user's local timezone
  const formattedDate = momentTimezone
    .utc(dateString) // Treat the input as UTC
    .tz(userTimezone) // Convert to local timezone
    .format(formatWithTimezone(formatString)) // Format with timezone abbreviation (e.g., IST)

  return formattedDate
}

export const formatWalletAddress = (
  address: string,
  firstDigitNumbers?: number,
  lastDigitNumbers?: number
) => {
  const numberOfLastDigits = lastDigitNumbers ?? 4
  if (!address) return ''

  const add1 = address.substring(0, firstDigitNumbers ?? 7)
  const add2 = address.substring(address.length - numberOfLastDigits)
  const finalAdd = `${add1}....${add2}`
  return finalAdd
}
export const getFileType = (_file: any): string => {
  const { name } = _file
  const nameSplits = name.split('.')
  const ext = nameSplits[nameSplits.length - 1]
  return ext
}

export function bytesToKB(bytes: number) {
  if (bytes < 1024) return `1 KB`
  return Math.floor(bytes / 1024) + ' KB'
}
export type AllowedExtensions = 'image' | 'video' | 'json'

export const validateFile = (
  _file: any,
  limit_size: number = 15,
  type: AllowedExtensions = 'image'
): [boolean, string] => {
  if (!_file || _file.length === 0) {
    return [false, 'No File Selected']
  }

  let status = true
  let msg = 'success'

  const allowedExtensionsMap: { [key in AllowedExtensions]: string[] } = {
    image: ['png', 'svg', 'jpeg', 'jpg', 'gif', 'jfif'],
    video: [
      'mp4',
      'avi',
      'mkv',
      'mov',
      'wmv',
      'webm',
      'mpg',
      'mpeg',
      '3gp',
      'm4v',
    ],
    json: ['json'],
  }

  for (const element of _file) {
    const one_byte_to_mb = 1 / 1000000
    const file_size = one_byte_to_mb * element.size

    if (file_size > limit_size) {
      status = false
      msg = `Please upload ${
        type === 'video' ? 'a video' : 'an image'
      } less than ${limit_size}mb`
    }

    const { name } = element
    const nameSplits = name.split('.')
    const ext = nameSplits[nameSplits.length - 1].toLowerCase()

    const allowedExtensions = allowedExtensionsMap[type]

    if (!allowedExtensions.includes(ext)) {
      status = false
      msg = `Please upload only ${allowedExtensions.join('/')} files`
    }
  }
  return [status, msg]
}

export function timestampToTimeRemaining(timestamp: string = ''): string[] {
  if (!timestamp) return []
  const currentMoment = moment().local()
  const futureMoment = moment(timestamp).local()
  const duration = moment.duration(futureMoment.diff(currentMoment))

  const years = Math.floor(duration.asYears())
  const months = Math.floor(duration.asMonths() % 12)
  const days = Math.floor(duration.asDays() % 7)
  const hours = Math.floor(duration.asHours() % 24)
  const minutes = Math.floor(duration.asMinutes() % 60)
  const seconds = Math.floor(duration.asSeconds() % 60)

  const timeRemaining = []
  if (years) {
    timeRemaining.push(`${years} year${years > 1 ? 's' : ''}`)
  }
  if (months) {
    timeRemaining.push(`${months} month${months > 1 ? 's' : ''}`)
  }
  if (days) {
    timeRemaining.push(`${days} day${days > 1 ? 's' : ''}`)
  }
  if (hours || hours === 0) {
    timeRemaining.push(`${hours} hour${hours > 1 ? 's' : ''}`)
  }
  if (minutes || minutes === 0) {
    timeRemaining.push(`${minutes} minute${minutes > 1 ? 's' : ''}`)
  }
  if (seconds || seconds === 0) {
    timeRemaining.push(`${seconds} second${seconds > 1 ? 's' : ''}`)
  }
  return timeRemaining
}

export function timestampToTimeAgo(timestamp = ''): string {
  if (!timestamp) return 'N/A'
  const currentMoment: Date = new Date()
  const pastMoment: Date | null = chrono.parseDate(timestamp)

  if (!pastMoment) return 'Invalid Timestamp'

  const duration: number = currentMoment.getTime() - pastMoment.getTime()

  const years = Math.floor(duration / (1000 * 60 * 60 * 24 * 365))
  const months = Math.floor((duration / (1000 * 60 * 60 * 24 * 30)) % 12)
  const weeks = Math.floor((duration / (1000 * 60 * 60 * 24 * 7)) % 4)
  const days = Math.floor((duration / (1000 * 60 * 60 * 24)) % 7)
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24)
  const minutes = Math.floor((duration / (1000 * 60)) % 60)
  const seconds = Math.floor((duration / 1000) % 60)

  const timeAgo = []
  if (years) {
    timeAgo.push(`${years} y`)
  }
  if (months) {
    timeAgo.push(`${months} m`)
  }
  if (weeks) {
    timeAgo.push(`${weeks} w`)
  }
  if (days) {
    timeAgo.push(`${days} d`)
  }
  if (hours) {
    timeAgo.push(`${hours} h`)
  }
  if (minutes) {
    timeAgo.push(`${minutes} min`)
  }
  if (seconds) {
    timeAgo.push(`${seconds} sec`)
  }
  return timeAgo.join(', ') + ' ago'
}

export function trimString(str: string, maxLength: number, noAppend?: boolean) {
  if (!str) return ''
  if (str.length > maxLength) {
    return noAppend
      ? str.substring(0, maxLength)
      : str.substring(0, maxLength) + '...'
  } else {
    return str
  }
}

export function getUniqueObjects<T>(array: T[], property: keyof T): T[] {
  const uniqueObjects: T[] = []
  const uniqueValues = new Set<T[keyof T]>()
  for (const obj of array) {
    if (!obj) continue
    const value = obj[property]
    if (!uniqueValues.has(value)) {
      uniqueValues.add(value)
      uniqueObjects.push(obj)
    }
  }
  return uniqueObjects
}

export function convertString(str: string): string {
  // Split the string by '-' to get individual words
  const words = str.split('-')

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  )

  // Join the words with spaces
  const result = capitalizedWords.join(' ')

  return result
}

export function htmlDecode(input: string) {
  if (!input) return ''
  const doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent ?? ''
}

export function convertTimeFilter(timeString: string): string | null {
  if (timeString.toString() === 'Invalid Date') return null

  const momentObj = moment(timeString, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')
  const temp = momentObj.add(23, 'hours')
  const date = temp.format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
  return date === 'Invalid date' ? 'N/A' : date
}
export function convertTimeFilter2(timeString: string): string {
  const momentObj = moment(timeString)
  const date = momentObj.format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
  return date === 'Invalid date' ? 'N/A' : date
}

export function convertTimeFormat(timeString: string): string | null {
  if (!timeString || timeString.toString() === 'Invalid Date') return null

  const momentObj = moment(timeString, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')
  const date = momentObj.format('YYYY-MM-DDTHH:mm:ss.SSSSSS')

  return date === 'Invalid date' ? 'N/A' : date
}

export function convertTimeFormat2(timeString: string): string {
  const momentObj = moment(timeString, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')
  const date = momentObj.format('DD/MM/YY')
  return date === 'Invalid date' ? 'N/A' : date
}

export function convertTimeFormat4(timeString: string): string {
  const momentObj = moment(timeString, 'YYYY-MM-DD')
  const temp = momentObj.add(1, 'days')
  const date = temp.format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
  return date === 'Invalid date' ? '' : date
}

export function convertTimeFormat5(timeString: string): string {
  const momentObj = moment(timeString, 'YYYY-MM-DDTHH:mm:ss.SSSSSS')
  const date = momentObj.format('DD/MM/YYYY')
  return date === 'Invalid date' ? 'N/A' : date
}

export const convertTimeFormat6 = (timeString: string): string => {
  if (!timeString) return 'N/A'

  // Parse the date using chrono
  const pastMoment = chrono.parseDate(timeString)
  if (!pastMoment) return 'N/A'

  // Guess the user's local timezone
  const userTimezone = momentTimezone.tz.guess()

  // Base format string
  let formatString = 'DD MMM YYYY | hh:mm A'

  // Use the separate function to append timezone if needed
  formatString = formatWithTimezone(formatString)

  // Convert the date to the user's local timezone and format it
  return momentTimezone(pastMoment).tz(userTimezone).format(formatString)
}

export const convertTimeToUTC = (timeString: string | undefined): string => {
  if (!timeString) return 'N/A'
  const pastMoment: Date | null = chrono.parseDate(timeString)

  const momentObj = moment(pastMoment, 'YYYY-MM-DDTHH:mm:ss')
  const formattedDate = momentObj.format('MMM DD, YYYY')

  return formattedDate
}

export const convertTimeFormat7 = (timeString: string | undefined): string => {
  if (!timeString) return 'N/A'

  const pastMoment: Date | null = chrono.parseDate(timeString) // Parse the input string to a date object

  const momentObj = momentTimezone(pastMoment) // Create a moment object
  const userTimezone = momentTimezone.tz.guess() // Detect the user's local timezone

  // Base format for the date
  let formatString = 'MMM DD, YYYY'

  const formattedDate = momentObj
    .tz(userTimezone)
    .format(formatWithTimezone(formatString))
  return formattedDate
}

/**
 * Converts a time string or Moment object to ISO format.
 *
 * @param {string|Moment} timeString - The input time string or Moment object.
 * @returns {string} - The time string converted to ISO format or 'N/A' if conversion fails.
 *
 * @example
 * // Example 1: Convert a Moment object to ISO format
 * const momentObject = moment('2024-01-31T12:34:56.789Z');
 * const isoResult1 = convertToISOFormat(momentObject);
 * console.log(isoResult1); // Output: '2024-01-31T12:34:56.789Z'
 *
 * @example
 * // Example 3: Handling invalid input
 * const isoResult3 = convertToISOFormat('invalidDateString');
 * console.log(isoResult3); // Output: 'N/A'
 */
export function convertToISOFormat(timeString: string | Moment): string {
  const momentObj = moment(timeString, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
  const isoFormat = momentObj.toISOString()
  return isoFormat === 'Invalid date' ? 'N/A' : isoFormat
}

export const getDayNameTimezone = (timestamp: string) => {
  const timestampInMilliseconds = Number(timestamp) * 1000

  const localOffset = new Date().getTimezoneOffset() // in minutes

  const localOffsetMilliseconds = 60 * 1000 * localOffset

  const date = moment(timestampInMilliseconds + localOffsetMilliseconds)

  const dayName = date.format('dddd')

  return dayName
}

export const getDayHour = (timestamp: string) => {
  const timestampInMilliseconds = Number(timestamp) * 1000
  const date = moment(timestampInMilliseconds)
  return date.hour()
}

export const getDate = (timestamp: string) => {
  const timestampInMilliseconds = Number(timestamp) * 1000
  const date = moment(timestampInMilliseconds)
  return date.date()
}

export const getDateWithMonth = (timestamp: string) => {
  const date = moment(timestamp, 'YYYY-MM-DDTHH:mm:ss.SSS')
  return date.format('MMMDD') // Use 'MMMDD' format to get month abbreviation followed by the day
}

export const getMonth = (timestamp: string) => {
  const timestampInMilliseconds = Number(timestamp) * 1000
  const date = moment(timestampInMilliseconds)
  let monthName = date.format('MMMM')
  monthName = trimString(monthName, 3, true)
  return monthName
}

export const getMonthYear = (timestamp: string) => {
  const timestampInMilliseconds = Number(timestamp) * 1000
  const date = moment(timestampInMilliseconds)
  let monthName = date.format('MMMM')
  monthName = trimString(monthName, 3, true)
  const year = date.year()
  return `${monthName}'${year}`
}

export function abbreviateNumber(value: number) {
  let newValue: any = value

  if (isNaN(newValue)) return 'N/A'

  if (value >= 1000) {
    let suffixes = ['', 'k', 'Million', 'Billion', 'Trillion']
    let suffixNum = Math.floor(('' + value).length / 3)
    suffixNum = suffixNum > 4 ? 4 : suffixNum
    let shortValue: any = ''
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision)
      )
      let dotLessShortValue = String(shortValue).replace(/[^a-zA-Z0-9 ]+/g, '')

      if (dotLessShortValue.length <= 2) {
        break
      }
    }
    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1)

    newValue = `$ ${shortValue}` + suffixes[suffixNum]
  } else {
    newValue = `$ ${newValue}`
  }

  return newValue ?? 'N/A'
}

export function calculateDivision(numberString: string, power: number): string {
  const dividend = new BigNumber(numberString)
  const divisor = new BigNumber(10).exponentiatedBy(power)
  const result = dividend.dividedBy(divisor).toString()
  return result
}
export function calculateMultiply(
  numberString: string | number,
  power: number
): string {
  const number = new BigNumber(numberString)
  const factor = new BigNumber(10).exponentiatedBy(power)
  const result = number.multipliedBy(factor).toString()
  return result
}

export const isArrayEqual = function (x: any, y: any) {
  return _(x).xorWith(y, _.isEqual).isEmpty()
}

export const getUserName = (details: INameDetails) => {
  if (details?.display_name) {
    return details.display_name
  }
  if (details?.first_name || details?.last_name) {
    let firstName = details.first_name ?? ''
    let lastName = details.last_name ?? ''
    return firstName + (lastName ? ` ${lastName}` : '')
  }
  return 'Unknown'
}

export const getPrice = (
  price: any,
  chainType: string | undefined,
  fixTo?: number,
  showPrecisedValue?: boolean
) => {
  if (!price || !chainType) return ''
  let p: any = calculateDivision(price, chainType === 'SOL' ? 9 : 18)
  if (isNaN(p)) return ''

  const precisionCount = fixTo ?? 3
  const threshold = Math.pow(10, -precisionCount)
  const fixedPrecision = threshold.toFixed(fixTo)

  if (!showPrecisedValue)
    if (p < fixedPrecision) return `< ${fixedPrecision} ` + chainType

  return Number(p).toFixed(fixTo ?? 3) + ' ' + chainType
}

export const getPrecisePrice = (
  price: any,
  chainType: string | undefined,
  hideChainType?: boolean
) => {
  if (!price || !chainType) return ''
  let p: any = calculateDivision(price, chainType === 'SOL' ? 9 : 18)
  if (isNaN(p)) return ''

  if (hideChainType) return Number(p).toFixed(chainType === 'SOL' ? 9 : 18)
  else return Number(p).toFixed(chainType === 'SOL' ? 9 : 18) + ' ' + chainType
}

export const convertToSmallestUnit = (
  price: any,
  chainType: string | undefined
) => {
  if (!price || !chainType) return ''
  if (chainType === 'USDC') return price

  let p: any = calculateMultiply(price, chainType === 'SOL' ? 9 : 18)
  if (isNaN(p)) return ''
  return p
}
export const convertFromSmallestUnit = (
  price: any,
  chainType: string | undefined
) => {
  if (!price || !chainType) return ''

  let p: any = calculateDivision(price, chainType === 'SOL' ? 9 : 18)
  if (isNaN(p)) return ''
  return p
}

export const getLink = (txHash: string, chainType: string) => {
  if (chainType === 'ETH') {
    return `${BLOCK_EXPLORE_URLS.sepolia}/tx/${txHash}`
  } else {
    return `${BLOCK_EXPLORE_URLS.solana}/tx/${txHash}?cluster=devnet`
  }
}
export const getLinkForTransaction = (txHash: string, chainType: string) => {
  if (chainType === 'ETH') {
    return `${BLOCK_EXPLORE_URLS.sepolia}/tx/${txHash}`
  }
  if (chainType === 'SOL') {
    return `${BLOCK_EXPLORE_URLS.solana}/tx/${txHash}?cluster=devnet`
  } else {
    return `${BLOCK_EXPLORE_URLS.sepolia}/`
  }
}

export function calculatePastTimeDateStamp(
  duration: moment.DurationInputArg1
): string {
  // Get the current time
  const currentTime = moment()

  // Subtract the specified duration from the current time
  const pastTime = currentTime.subtract(duration, 'hours')

  // Format the past time as a date stamp in the desired format
  const dateStamp = pastTime.format('YYYY-MM-DDTHH:mm:ss.SSSSSS')

  return dateStamp
}

export function deepCompareArray(arr1: string[], arr2: string[]): boolean {
  // If the arrays have different lengths, they are not equal
  if (arr1?.length !== arr2?.length) {
    return false
  }

  // Recursively compare each element of the arrays
  for (let i = 0; i < arr1?.length; i++) {
    const item1 = arr1?.[i]
    const item2 = arr2?.[i]

    // If the items are arrays, recursively compare them
    if (Array.isArray(item1) && Array.isArray(item2)) {
      if (!deepCompareArray(item1, item2)) {
        return false
      }
    }
    // If the items are objects, recursively compare them
    else if (typeof item1 === 'object' && typeof item2 === 'object') {
      if (!deepCompareObject(item1, item2)) {
        return false
      }
    }
    // Compare the items directly
    else if (item1 !== item2) {
      return false
    }
  }

  // All elements are equal
  return true
}

// Helper function for deep comparing objects
export function deepCompareObject(obj1: any, obj2: any): boolean {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // If the objects have different numbers of keys, they are not equal
  if (keys1?.length !== keys2?.length) {
    return false
  }

  // Recursively compare each key-value pair in the objects
  for (const key of keys1) {
    const value1 = obj1?.[key]
    const value2 = obj2?.[key]

    // If the values are arrays, recursively compare them
    if (Array.isArray(value1) && Array.isArray(value2)) {
      if (!deepCompareArray(value1, value2)) {
        return false
      }
    }
    // If the values are objects, recursively compare them
    else if (typeof value1 === 'object' && typeof value2 === 'object') {
      if (!deepCompareObject(value1, value2)) {
        return false
      }
    }
    // Compare the values directly
    else if (value1 !== value2) {
      return false
    }
  }

  // All key-value pairs are equal
  return true
}
export const getAuctionTimeText = (
  endTime: number,
  isStart?: boolean,
  isDutch?: boolean,
  dutchEndPrice?: string
) => {
  const timeFormat = moment(endTime * 1000).format('hh:mm a')
  const dateFormat = moment(endTime * 1000).format('MMM DD, YYYY')
  if (isStart) {
    return `Sale starts on ${dateFormat} at ${timeFormat}`
  } else if (isDutch) {
    return `Sale ends ${dateFormat} at ${timeFormat} at ${dutchEndPrice}`
  } else {
    return `Sale ends ${dateFormat} at ${timeFormat}`
  }
}

export const getLaunchpadTimeText = (
  endTime: number,
  isStart?: boolean
): string => {
  // Guess the user's local timezone
  const userTimezone = momentTimezone.tz.guess()

  // Convert endTime from seconds to milliseconds and adjust for timezone
  const endTimeMoment = momentTimezone.utc(endTime * 1000).tz(userTimezone)

  // Base format strings for time and date

  let dateFormat = 'MMM DD, YYYY [at] hh:mm a'

  // Use the separate function to append timezone if needed
  dateFormat = formatWithTimezone(dateFormat)

  // Format the time and date with or without the timezone
  const dateText = endTimeMoment.format(dateFormat)

  // Determine the message based on whether the launchpad has started or ended
  if (new Date() > new Date(endTime * 1000)) {
    return `Launchpad ended on ${dateText}`
  }

  if (isStart) {
    return `Launchpad starts on ${dateText}`
  } else {
    return `Launchpad ends on ${dateText}`
  }
}

export const getTimeText = (endTime: number, isStart?: boolean) => {
  // Guess the user's local timezone
  const userTimezone = momentTimezone.tz.guess()

  // Convert endTime from seconds to milliseconds and adjust for timezone
  const endTimeMoment = momentTimezone.utc(endTime * 1000).tz(userTimezone)

  // Base format string combining date and time with "at" between them
  let combinedFormat = 'MMM DD, YYYY [at] hh:mm a'

  // Use the separate function to append timezone if needed
  combinedFormat = formatWithTimezone(combinedFormat)

  // Format the date and time together
  const dateTimeText = endTimeMoment.format(combinedFormat)

  // Determine the message based on whether the sale has started or ended
  if (isStart) {
    return `Sale starts on ${dateTimeText}`
  } else {
    if (new Date() > new Date(endTime * 1000)) {
      return `Sale ended on ${dateTimeText}`
    }
    return `Sale ends on ${dateTimeText}`
  }
}

export const getFormatedPrice = (
  price?: number | string,
  chain?: SUPPORTED_CHAIN | undefined | string,
  fixTo?: number,
  showPrecisedValue?: boolean
) => {
  if (chain === SUPPORTED_CHAIN?.ETH) {
    return getPrice(
      price?.toString(),
      SUPPORTED_CHAIN?.ETH,
      fixTo,
      showPrecisedValue
    )
  } else {
    return getPrice(
      price?.toString(),
      SUPPORTED_CHAIN?.SOL,
      fixTo,
      showPrecisedValue
    )
  }
}

export const calculatePercentage = (value: number, total: number): number =>
  (value / total) * 100

export function renderCharactersFromAtoZ(): string[] {
  const startCharCode = 'A'.charCodeAt(0)
  const endCharCode = 'Z'.charCodeAt(0)

  const characters = Array.from(
    { length: endCharCode - startCharCode + 1 },
    (_, index) => String.fromCharCode(startCharCode + index)
  )

  return characters.map((character) => character)
}

export function addProtocolToUrl(url: string) {
  // Check if the URL starts with "http://" or "https://"
  if (!/^https?:\/\//i.test(url)) {
    // If not, add "http://" as the default protocol
    url = 'http://' + url
  }
  return url
}

export function removeHttpsPrefixAndTrailingSlash(input: string): string {
  if (!input) return ''
  return input.replace(/^https:\/\/(.*?)\/?$/, '$1')
}

export function extractUsername(url: string): string {
  if (!url) return ''
  const parts = url.split('/')
  const lastWord = '@' + parts[parts.length - 1]
  if (lastWord.length > 10) {
    return lastWord.substring(0, 10) + '..'
  }

  return lastWord
}

export const getDollarPrice = (
  price: string,
  chainType: string,
  ethPrice: number,
  solPrice: number,
  showZero?: boolean,
  showPrecisedValue?: boolean
): string => {
  const divisionFactor = chainType === 'SOL' ? 9 : 18
  const parsedPrice = parseFloat(price)

  if (!isNaN(parsedPrice)) {
    const price = calculateDivision(parsedPrice.toString(), divisionFactor)
    const multiplier = chainType === SUPPORTED_CHAIN.ETH ? ethPrice : solPrice

    if (showPrecisedValue) {
      const formattedTotalPrice = Number(price) * multiplier
      return `$ ${formattedTotalPrice.toFixed(8)}`
    } else {
      const formattedTotalPrice = (Number(price) * multiplier).toFixed(4)
      return parseFloat(formattedTotalPrice) < 0.001
        ? '$ < 0.001'
        : `$ ${Number(formattedTotalPrice).toFixed(3)}`
    }
  } else {
    return showZero ? '$ 0.0000' : `--`
  }
}

export const formatTime = (dateTimeString: string) => {
  // Base format for the date
  let formatString = 'MMM D, YYYY - h:mma'

  const formattedDate = momentTimezone
    .utc(dateTimeString) // Treat the input as UTC
    .tz(momentTimezone.tz.guess()) // Convert to local timezone
    .format(formatWithTimezone(formatString)) // Format the date
  return formattedDate
}

export const generateExplorerUrl = (
  transactionHash: string,
  chainType: string,
  paymentType?: string
) => {
  if (chainType === 'ETH' || paymentType === 'ETH') {
    return `${serverConfig.ethereumExplorerUrl}${transactionHash}`
  } else {
    return `${serverConfig.solanaExplorerUrl}${transactionHash}`
  }
}

export const getTrimmedAssetSource = (source: string | undefined) => {
  const lowercasedSource = source?.toLowerCase()
  let assetSource = ''

  switch (lowercasedSource) {
    case ASSET_SOURCE.XSTRELA.toLowerCase():
      assetSource = 'XStrela'
      break
    case ASSET_SOURCE.OPENSEA.toLowerCase():
      assetSource = 'OpenSea'
      break

    default:
      assetSource = 'N/A'
      break
  }

  return trimString(assetSource, 9)
}

export const isDateValid = (dateString: string) => {
  const date = new Date(dateString)

  if (dateString !== 'Invalid Date') {
    return !isNaN(date?.getTime())
  } else {
    return false
  }
}

export const renderEventType = (
  event: string,
  subEvent: string,
  source: string | undefined
) => {
  if (event === EVENT_TYPE.SALE) {
    if (
      subEvent === EVENT_SUB_TYPE.ENGLISH_AUCTION_SALE ||
      subEvent === EVENT_SUB_TYPE.DUTCH_AUCTION_SALE
    ) {
      return `Auction (${
        subEvent === EVENT_SUB_TYPE.ENGLISH_AUCTION_SALE ? 'English' : 'Dutch'
      })`
    }
    if (
      !subEvent &&
      source?.toLowerCase() === ASSET_SOURCE.OPENSEA.toLowerCase()
    ) {
      return 'Sale'
    }
    if (subEvent === EVENT_SUB_TYPE.STANDARD_SALE) {
      // No change needed for Standard Sale
    }
  } else if (event === EVENT_TYPE.MINT) {
    return 'Mint'
  }
  return 'Sale'
}

export const timestampToDate = (timestamp: number | null | undefined) => {
  if (!timestamp) return

  const userTimezone = momentTimezone.tz.guess() // Detect the user's local timezone
  const date = momentTimezone.utc(timestamp * 1000) // Multiply by 1000 to convert seconds to milliseconds

  // Base format for the date
  let formatString = 'DD MMM YYYY'

  // Convert to local timezone and format the date
  const dateString = date
    .tz(userTimezone)
    .format(formatWithTimezone(formatString))

  return dateString
}

export const timestampToDateAndTime = (
  timestamp: number | null | undefined
) => {
  if (!timestamp) return

  // Determine if the timestamp is in seconds and convert to milliseconds if needed
  const isSeconds = timestamp < 10000000000 // A rough check for second format
  const adjustedTimestamp = isSeconds ? timestamp * 1000 : timestamp

  const userTimezone = momentTimezone.tz.guess() // Detect the user's local timezone
  const date = momentTimezone.utc(adjustedTimestamp) // Treat the timestamp as UTC

  // Base format for the date
  let formatString = 'DD MMM YYYY | hh:mm A'

  // Convert to local timezone and format the date
  const dateString = date
    .tz(userTimezone)
    .format(formatWithTimezone(formatString))

  return dateString
}

export const timestampToDateAndTime2 = (time: string | null | undefined) => {
  if (!time) return

  const userTimezone = momentTimezone.tz.guess() // Detect the user's local timezone
  const date = momentTimezone.utc(time) // Treat the input string as UTC

  // Base format for the date
  let formatString = 'DD MMM YYYY | hh:mm A'

  // Convert to local timezone and format the date
  const dateString = date
    .tz(userTimezone)
    .format(formatWithTimezone(formatString))

  return dateString
}

export const capitalizeFirstLetter = (sentence: string) => {
  if (!sentence) return ''

  const words = sentence?.split(/\s+/) // Use a regular expression to handle multiple spaces between words
  const capitalizedWords = words?.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  )
  return capitalizedWords?.join(' ')
}

export const getPresignedUrlContentType = (contentType: string | undefined) => {
  switch (contentType) {
    case 'image/svg+xml':
      return 'SVG'
    case 'image/png':
      return 'PNG'
    case 'image/gif':
      return 'GIF'
    case 'image/jpeg':
      return 'JPEG'
    case 'image/webp':
      return 'WEBP'

    default:
      return null
  }
}

export const joinArrayWithSpace = (arr: (string | number)[]): string =>
  arr.map(String).join(' ')

export const getFrequencyStatus = (seconds: number) => {
  if (!seconds) return '--'

  const secondsInDay = 86400 // 24 * 60 * 60
  const secondsInWeek = 604800 // 7 * 24 * 60 * 60
  const secondsInMonth = 2592000 // Assuming a month has 30 days (30 * 24 * 60 * 60)

  switch (true) {
    case seconds <= secondsInDay:
      return 'Daily'
    case seconds <= secondsInWeek:
      return 'Weekly'
    case seconds <= secondsInMonth:
      return 'Monthly'
    default:
      return 'More than a month'
  }
}

export const getRewardTypeLabel = (rewardType: string) => {
  switch (rewardType) {
    case QUEST_REWARD_TYPES.QUEST_STARTER_PACK_AWARD:
      return 'Starter Pack'
    case QUEST_REWARD_TYPES.QUEST_NFT_AWARD:
      return 'Digital Asset'
    case QUEST_REWARD_TYPES.QUEST_BADGE_AWARD:
      return 'Badge'
    case QUEST_REWARD_TYPES.QUEST_PHYSICAL_AWARD:
      return 'Physical Item'
    case QUEST_REWARD_TYPES.QUEST_AVATAR_TRAIT_AWARD:
      return 'Avatar Trait'

    default:
      return ''
  }
}

export const findTotalSupply = (data: I_RewardsData[], id: string) => {
  const num = data?.find((item: any) => item.id === id)?.total_supply
  if (num === undefined) return 0
  else return Number(num)
}

export const getStatusText = (startTime: string, endTime?: string | null) => {
  const startParsedTime: Date | null = chrono.parseDate(startTime)

  const now = moment()
  const startMoment = moment(startParsedTime)

  if (endTime === null || endTime === undefined) {
    if (now.isBefore(startMoment)) {
      return 'Upcoming'
    } else {
      return 'Active'
    }
  } else {
    const endParsedTime: Date | null = chrono.parseDate(endTime)

    const endMoment = moment(endParsedTime)
    if (now.isBefore(startMoment)) {
      return 'Upcoming'
    } else if (now.isBetween(startMoment, endMoment)) {
      return 'Active'
    } else {
      return 'Ended'
    }
  }
}

export const convertTimezoneToGMT = (offsetInSeconds: number) => {
  // Convert offset to hours and minutes
  const offsetHours = Math.floor(offsetInSeconds / 3600)
  const offsetMinutes = Math.floor((offsetInSeconds % 3600) / 60)

  // Format the result in hours and minutes
  const hours = offsetHours < 0 ? '-' : '+'
  const absOffsetHours = Math.abs(offsetHours)
  const minutes = String(Math.abs(offsetMinutes)).padStart(2, '0')

  // Construct the GMT representation
  const gmtRepresentation = `GMT${hours}${absOffsetHours}:${minutes}`

  return gmtRepresentation
}

export function isoToEpoch(isoDate: string): number {
  const dateObj = new Date(isoDate)
  const epoch = Math.floor(dateObj.getTime() / 1000)
  return epoch
}

export const calculateStartTime = () => {
  const currentTime = new Date()
  currentTime.setMinutes(currentTime.getMinutes() + 15)

  let currentHour = currentTime.getHours()
  let currentMinutes = currentTime.getMinutes()

  // Adjust hour if minutes exceed 59
  if (currentMinutes >= 60) {
    currentMinutes -= 60
    currentHour += 1
  }

  return `${currentHour}:${currentMinutes.toString().padStart(2, '0')}`
}

export const clearStorage = () => {
  const isRememberMe = ls.get('isChecked') === 'true'
  const localStorageKeys = Object.keys(localStorage)

  sessionStorage.clear()

  if (isRememberMe) {
    // if remember me is checked skip isChecked and email and remove rest
    const skipKeys = ['isChecked', 'email']
    localStorageKeys.forEach((key) => {
      if (skipKeys.includes(key)) {
        return
      } else {
        ls.remove(key)
      }
    })
  } else {
    localStorage.clear()
  }
}

export const weiToEth = (weiValue?: string | number) => {
  if (weiValue) {
    const ethAmount = new BigNumber(weiValue).div('1e18')
    return ethAmount?.toString()

    // return ethers.utils.formatEther(weiValue).toString()
  }

  return '0'
}
export const ethToWei = (ethValue?: string | number) => {
  if (ethValue) {
    const weiAmount = new BigNumber(ethValue).multipliedBy('1e18')
    return weiAmount.toString()
  }

  return '0'
}

export const calculatePayloadSizeInMB = <T extends object>(data: T): number => {
  const payload = JSON.stringify(data)
  if (payload !== undefined || payload !== null) {
    const sizeInBytes = new TextEncoder().encode(payload).length
    const sizeInMegabytes = sizeInBytes / 1048576 // Convert bytes to megabytes
    return sizeInMegabytes
  } else return 0
}

export const getChainIcon = (chain: SUPPORTED_CHAIN): string => {
  switch (chain) {
    case SUPPORTED_CHAIN.ETH:
      return EthIcon
    case SUPPORTED_CHAIN.SOL:
      return SolIcon
    default:
      return EthIcon
  }
}
export const solToLamport = (solValue?: string | number) => {
  if (solValue) {
    const lamportAmount = new BigNumber(solValue).multipliedBy('1e9')
    return lamportAmount.toString()
  }

  return '0'
}

export const getSolFromLamport = (lamport: number, precision: number = 8) => {
  if (lamport) {
    const LAMPORTS_PER_SOL = new BigNumber(1_000_000_000)
    const lamportsBigNumber = new BigNumber(lamport)
    return lamportsBigNumber
      .dividedBy(LAMPORTS_PER_SOL)
      ?.toNumber()
      ?.toFixed(precision)
  }

  return '0'
}

export const getFormatedConvertedPrice = (
  price: number | string | null | undefined,
  chain?: SUPPORTED_CHAIN
) => {
  if (chain === SUPPORTED_CHAIN?.ETH) {
    return weiToEth(price?.toString())
  } else {
    return getSolFromLamport(Number(price)).toString()
  }
}

export const dateStringToUnix = (date: string) => {
  if (date) return moment(date).unix() * 1000
}

export const getTimeRemaining = (endTime: string) => {
  const endDate = moment(endTime)
  const now = moment()

  // Calculate the duration in milliseconds
  const duration = moment.duration(endDate.diff(now))

  if (duration.asMilliseconds() <= 0) {
    return 'ENDED'
  }

  // Extract hours, minutes, and seconds from the duration
  const hours = Math.floor(duration.asHours())
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  // Format hours, minutes, and seconds with leading zeros
  const formattedHours = hours < 10 ? `0${hours}` : hours
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds

  return `ENDS IN ${formattedHours}H ${formattedMinutes}M ${formattedSeconds}S`
}

export function isObjectValid(obj: any) {
  return obj !== undefined && obj !== null && Object.keys(obj).length > 0
}

export function formatNumber(number: any) {
  if (number === undefined || number === null) return 0
  const num = Number(number)

  if (num >= 1e9) {
    return (num / 1e9).toFixed(1) + 'B'
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(1) + 'M'
  } else if (num >= 1e5) {
    return (num / 1e3).toFixed(1) + 'K'
  } else {
    return num.toString()
  }
}

export const getOptimizedImage = async (file: File) => {
  if (!file) {
    throw new Error('File is required')
  }
  if (!file.type.startsWith('image/')) {
    throw new Error('Image file is required')
  }
  try {
    const options: Options = {
      maxSizeMB: 2.5,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    }
    const compressedFile = await imageCompression(file, options)
    return compressedFile
  } catch (error) {
    throw error
  }
}

export const removeStringsFromArray = (
  array: string[],
  stringsToRemove?: string[]
): string[] => {
  if (!array?.length) return []

  if (!stringsToRemove || stringsToRemove.length === 0) {
    return array.length > 0 ? array : []
  }

  const filteredArray = array.filter((item) => !stringsToRemove.includes(item))

  return filteredArray.length ? filteredArray : []
}

export const replaceKeysWithValues = async (
  array: string[],
  columnsMap: any
): Promise<string[] | null> => {
  if (!array?.length) return null

  const newArray: string[] = array.flatMap((item) => {
    if (columnsMap[item]) {
      return columnsMap[item] // Replace key with values if key is found in columnsMap
    }
    return item // Return the item unchanged if no match is found
  })

  return newArray.length ? newArray : null
}
export const convertDisplayToQuery = (
  displayQuery: any,
  displayToKeyMap: any
) => {
  let backendQuery = displayQuery
  displayToKeyMap.forEach((value: any, key: any) => {
    const regex = new RegExp(`\\b${key}\\b`, 'gi')
    backendQuery = backendQuery.replace(regex, value)
  })
  return backendQuery
}

export const transformString = (str: string) => {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const getTag = (prevData: any, newData: any) => {
  if (prevData && !newData) {
    return 'Removed'
  } else if (!prevData && newData) {
    return 'Added'
  } else if (prevData && newData && prevData !== newData) {
    return 'Changed'
  } else {
    return 'Unchanged'
  }
}

export const getNextSortOrder = (currentSortOrder: string) => {
  if (currentSortOrder === '') {
    return 'asc'
  } else if (currentSortOrder === 'asc') {
    return 'desc'
  } else {
    return ''
  }
}

export const getSortOrder = (
  currentSortOrder: string,
  ascendingOrder: string | boolean,
  descendingOrder: string | boolean
) => {
  if (currentSortOrder === '') {
    return { name: 'Highest', value: descendingOrder }
  } else if (currentSortOrder === 'asc') {
    return { name: 'Lowest', value: ascendingOrder }
  } else {
    return { name: 'Default', value: null }
  }
}

const getStartAndEndDate = (period: string) => {
  let start, end

  switch (period) {
    case 'today':
      start = moment().startOf('day')
      end = moment().endOf('day')
      break
    case 'this_week':
      start = moment().startOf('week')
      end = moment().endOf('week')
      break
    case 'last_week':
      start = moment().subtract(1, 'week').startOf('week')
      end = moment().subtract(1, 'week').endOf('week')
      break
    case 'this_month':
      start = moment().startOf('month')
      end = moment().endOf('month')
      break
    case 'last_month':
      start = moment().subtract(1, 'month').startOf('month')
      end = moment().subtract(1, 'month').endOf('month')
      break
    case 'last_3_month':
      start = moment().subtract(3, 'months').startOf('month')
      end = moment()
      break
    case 'last_year':
      start = moment().subtract(1, 'year').startOf('year')
      end = moment().subtract(1, 'year').endOf('year')
      break
    default:
      start = end = null
  }

  return { start, end }
}

const getDateRange = (option: string, formatType: 'ISO' | 'Custom' = 'ISO') => {
  const { start, end } = getStartAndEndDate(option)

  if (!start || !end) return {}

  const formatFunction =
    formatType === 'ISO' ? convertToISOFormat : convertTimeFormat

  return {
    from: formatFunction(start?.toString()),
    to: formatFunction(end?.toString()),
  }
}

interface I_DateSortItem {
  name: string
  value:
    | {
        startDate: string
        endDate: string
      }
    | any
}

export const sortTimeOptionList: I_DateSortItem[] = [
  { name: 'Today', value: getDateRange('today') },
  { name: 'This week', value: getDateRange('this_week') },
  { name: 'Last week', value: getDateRange('last_week') },
  { name: 'This month', value: getDateRange('this_month') },
  { name: 'Last month', value: getDateRange('last_month') },
  { name: 'Last 3 month', value: getDateRange('last_3_month') },
  { name: 'Last year', value: getDateRange('last_year') },
]
export const sortTimeOptionList2: I_DateSortItem[] = [
  { name: 'Today', value: getDateRange('today', 'Custom') },
  { name: 'This week', value: getDateRange('this_week', 'Custom') },
  { name: 'Last week', value: getDateRange('last_week', 'Custom') },
  { name: 'This month', value: getDateRange('this_month', 'Custom') },
  { name: 'Last month', value: getDateRange('last_month', 'Custom') },
  { name: 'Last 3 month', value: getDateRange('last_3_month', 'Custom') },
  { name: 'Last year', value: getDateRange('last_year', 'Custom') },
]
export const convertUTCTimeByTimeZone = (
  utcDateTime: any,
  timezone?: string
) => {
  if (!utcDateTime) return null

  // Base format for the date
  let formatString = 'DD MMM YYYY | hh:mm A'

  const timeZone = timezone ?? moment.tz.guess()
  const convertedDate = momentTimezone.utc(utcDateTime).tz(timeZone)
  return convertedDate?.format(formatWithTimezone(formatString))
}
export const replaceKeysInObject = async (
  obj: any,
  keyMap: { [key: string]: string }
): Promise<any> => {
  if (!obj || typeof obj !== 'object') return null

  const newObj: any = {}

  Object.keys(obj).forEach((key) => {
    const newKey = keyMap[key] || key
    newObj[newKey] = obj[key]
  })

  return newObj
}

export const formatCurrency = (amount?: string | null): string => {
  // Check if amount is null, undefined, or an empty string
  if (amount === null || amount === undefined || amount.trim() === '') {
    return ''
  }

  // Convert the string to a number
  const numAmount = parseFloat(amount)

  // Check if the conversion is successful and the number is valid
  return !isNaN(numAmount) ? `$${numAmount}` : ''
}

export const handleFileUpload = async (
  file: any,
  mediaType?: mediaTypes,
  setProgress: () => void = () => {}
): Promise<[boolean, string]> => {
  try {
    const s3Res = await UploadImageToS3(file, setProgress, mediaType)
    return [true, s3Res]
  } catch (error) {
    console.error('Error in upload', error)
    return [false, 'Something went wrong, please try again']
  }
}

export const handlePageChange = (
  pageNumber: number,
  setPage: Function,
  filtersOptions: any,
  setFiltersOptions: Function
) => {
  setPage(pageNumber)
  setFiltersOptions({
    ...filtersOptions,
    page: pageNumber,
    limit: PAGE_LIMIT,
  })
}

export const uploadCover = async (
  selectedFile: any,
  isLoading: boolean,
  setIsLoading: (loading: boolean) => void,
  mediaType?: mediaTypes
): Promise<string> => {
  if (isLoading) return ''
  setIsLoading(true)
  try {
    const [status, msg] = await handleFileUpload(selectedFile, mediaType)
    if (!status) {
      toast.error(msg)
      return ''
    }
    return msg
  } catch (error) {
    toast.error('Something went wrong, please try again')
    return ''
  } finally {
    setIsLoading(false)
  }
}

export const numberInputOnWheelPreventChange = (e: any) => {
  // Prevent the input value change
  e.target.blur()

  // Prevent the page/container scrolling
  e.stopPropagation()

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    e.target.focus()
  }, 0)
}

// Function to return the formatted date with optional timezone
const formatWithTimezone = (formatString: string): string => {
  // Append timezone if SHOW_TIMEZONE is true
  if (SHOW_TIMEZONE) {
    formatString += ' (z)'
  }
  return formatString
}
