import {
  editGameDetailsPath,
  gamesPath,
  signInPath,
  viewGameChangesPath,
  viewGamePagePath,
  viewGameRequestPagePath,
} from 'logic/paths'
import React from 'react'

const GamesPage = React.lazy(() =>
  import('modules/pages/games').then((module) => ({
    default: module.GamesPage,
  }))
)
const GameDetailsPage = React.lazy(() =>
  import('modules/pages/games/GameDetailsPage').then((module) => ({
    default: module.GameDetailsPage,
  }))
)
const EditPlayGame = React.lazy(() =>
  import('modules/pages/games/editPlayGame/EditPlayGame').then((module) => ({
    default: module.EditPlayGame,
  }))
)

const GameChangesPage = React.lazy(() =>
  import('modules/pages/games/gameChangesPage/GameChangesPage').then(
    (module) => ({
      default: module.GameChangesPage,
    })
  )
)

const commonRoutes = [
  {
    path: gamesPath,
    element: GamesPage,
    protected: true,
    title: 'Player Profiles',
    pathType: 0,
    redirect: signInPath,
  },

  {
    path: editGameDetailsPath,
    element: EditPlayGame,
    protected: true,
    title: 'Edit game page',
    pathType: 0,
    redirect: signInPath,
  },

  {
    path: viewGamePagePath,
    element: GameDetailsPage,
    protected: true,
    title: 'Game Details',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: viewGameChangesPath,
    element: GameChangesPage,
    protected: true,
    title: 'View game changes page',
    pathType: 0,
    redirect: signInPath,
  },
]

const adminRoutes = [
  ...commonRoutes,
  {
    path: viewGameRequestPagePath,
    element: GameDetailsPage,
    protected: true,
    title: 'Game Details',
    pathType: 0,
    redirect: signInPath,
  },
]

export const gameAdminRoutes = [...adminRoutes]
export const gamePublisherRoutes = [...commonRoutes]
