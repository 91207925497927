import { withHeader } from 'shared/hocs/withHeader'
import { RouteDefinition } from 'utils/type'
import { baseRoutes } from './baseRoutes'
import { subAdminRoutes } from './moduleRoutes/subAdmin.route'

// Routes accessible by Super Admin
const routes = [...baseRoutes, ...subAdminRoutes]

export const superAdminRoutes: RouteDefinition[] = [...routes]
  .map((r) => ({
    ...r,
    element: withHeader(r),
  }))
  .concat()
