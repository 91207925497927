import { createAsyncThunk } from '@reduxjs/toolkit'
import { clearStorage, getLocalJwt } from 'utils'
import { isJwtExpired } from 'utils/isJsonExpired'
import {
  clearProfileDetail,
  setProfileDetail,
  setProfileDetailLoading,
} from './adminActions'
import { ls } from 'dev/secureLS'

export const isLoggedIn = createAsyncThunk(
  'login/checkUserLogin',
  (_, { dispatch }) => {
    const isLoggedIn = Boolean(getLocalJwt())

    if (isLoggedIn) {
      const jwt = getLocalJwt() ?? ''
      const isExpired = isJwtExpired(jwt)

      if (isExpired) {
        clearStorage()
        dispatch(clearProfileDetail({}))
        dispatch(setProfileDetailLoading(false))
        window.location.reload()
        return false
      }
      const profileData = ls.get('USER_DETAILS')
      if (profileData) {
        const profile = JSON.parse(profileData)
        dispatch(setProfileDetail(profile))
        dispatch(setProfileDetailLoading(false))
      }
    } else {
      setTimeout(() => {
        dispatch(setProfileDetailLoading(false))
      }, 500)
    }
    return isLoggedIn
  }
)
