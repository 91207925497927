import { useEffect, useState } from 'react'

import * as Styles from './style'

import { ReactComponent as BurgerIcon } from 'assets/icons/burger-icon-black.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/cross.svg'
import { AdminNavigation } from './components/AdminNavigation'

export const Navbar = () => {
  const [isExpandedNav, setIsExpandedNav] = useState(false)

  useEffect(() => {
    if (isExpandedNav) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'unset'
  }, [isExpandedNav])

  const handleBurgerClick = (flag: boolean) => {
    setIsExpandedNav(flag)
  }

  return (
    <Styles.Container>
      <Styles.NavContainer>
        <Styles.NavWrapper>
          <AdminNavigation className='desktopNavigation' />
          {!isExpandedNav && (
            <BurgerIcon
              height='25px'
              width='25px'
              className='burger'
              onClick={() => handleBurgerClick(true)}
              data-testid='burger'
            />
          )}
        </Styles.NavWrapper>
        {isExpandedNav && (
          <Styles.MobileNavWrapper data-testid='mobileNavWrapper'>
            <CloseIcon
              height='25px'
              width='25px'
              fill='currentColor'
              stroke='currentColor'
              className='burger closeNav'
              onClick={() => handleBurgerClick(false)}
              data-testid='closeIcon'
            />
            <AdminNavigation className='mobileNavigation' isMobile />
          </Styles.MobileNavWrapper>
        )}
      </Styles.NavContainer>
    </Styles.Container>
  )
}
