import {
  activeUsersPath,
  feesGeneratedPath,
  signInPath,
  totalSalesPath,
  transactionLogPath,
} from 'logic/paths'
import React from 'react'

const ActiveUsersPage = React.lazy(() =>
  import('modules/pages/dashboard/components/activeUsers/ActiveUsersPage').then(
    (module) => ({ default: module.ActiveUsersPage })
  )
)
const FeesGeneratedPage = React.lazy(() =>
  import(
    'modules/pages/dashboard/components/feesGenerated/FeesGeneratedPage'
  ).then((module) => ({ default: module.FeesGeneratedPage }))
)
const TotalSalesPage = React.lazy(() =>
  import('modules/pages/dashboard/components/totalSales/TotalSalesPage').then(
    (module) => ({ default: module.TotalSalesPage })
  )
)
const TransactionLogPage = React.lazy(() =>
  import(
    'modules/pages/dashboard/components/transactionLog/TransactionLogPage'
  ).then((module) => ({ default: module.TransactionLogPage }))
)

export const dashboardAdminRoutes = [
  {
    path: transactionLogPath,
    element: TransactionLogPage,
    protected: true,
    title: 'Transaction Log',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: totalSalesPath,
    element: TotalSalesPage,
    protected: true,
    title: 'Total Sales',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: feesGeneratedPath,
    element: FeesGeneratedPage,
    protected: true,
    title: 'Fees Generated',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: activeUsersPath,
    element: ActiveUsersPage,
    protected: true,
    title: 'Active Users',
    pathType: 0,
    redirect: signInPath,
  },
]
