import { homePath, rootPath, signInPath } from 'logic/paths'
import React from 'react'

import { withHeader } from 'shared/hocs/withHeader'
import { RouteDefinition } from 'utils/type'
import { authRoutes } from './moduleRoutes/auth.route'
import { gamePublisherRoutes } from './moduleRoutes/games.route'
import { launchpadAdminRoutes } from './moduleRoutes/launchpad.route'
import { questPublisherRoutes } from './moduleRoutes/quest.route'
import { webCarouselPublisherRoutes } from './moduleRoutes/webCarousel.route'
import { notFoundRoute } from './baseRoutes'
import { starterPackAdminRoutes } from './moduleRoutes/starterPack.route'

const Quest = React.lazy(() =>
  import('modules/pages/quest').then((module) => ({
    default: module.Quest,
  }))
)

const routes = [
  {
    path: rootPath,
    element: Quest,
    protected: true,
    title: 'Home',
    pathType: 0,
    redirect: signInPath,
  },

  {
    path: homePath,
    redirect: rootPath,
  },
  notFoundRoute,
  ...authRoutes,
  ...questPublisherRoutes,
  ...webCarouselPublisherRoutes,
  ...starterPackAdminRoutes,
  ...launchpadAdminRoutes,
  ...gamePublisherRoutes,
]

export const publisherRoutes: RouteDefinition[] = [...routes]
  .map((r) => ({
    ...r,
    element: withHeader(r),
  }))
  .concat()
