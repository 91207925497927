import { assetsPath, signInPath, viewAssetPagePath } from 'logic/paths'
import React from 'react'

const AssetsPage = React.lazy(() =>
  import('modules/pages/assets').then((module) => ({
    default: module.AssetsPage,
  }))
)
const AssetDetailsPage = React.lazy(() =>
  import('modules/pages/assets/AssetDetailsPage').then((module) => ({
    default: module.AssetDetailsPage,
  }))
)
export const assetsAdminRoutes = [
  {
    path: assetsPath,
    element: AssetsPage,
    protected: true,
    title: 'Assets',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: viewAssetPagePath,
    element: AssetDetailsPage,
    protected: true,
    title: 'View Asset',
    pathType: 0,
    redirect: signInPath,
  },
]
