import {
  forgotPasswordPath,
  resetPasswordPath,
  signInPath,
  verifyOtpPath,
} from 'logic/paths'
import React from 'react'

const LoginPage = React.lazy(() =>
  import('modules/pages/login').then((module) => ({
    default: module.LoginPage,
  }))
)

const OtpVerify = React.lazy(() =>
  import('modules/pages/forgotPassword/components/OtpVerify').then(
    (module) => ({ default: module.OtpVerify })
  )
)

const RequestOtpPage = React.lazy(() =>
  import('modules/pages/forgotPassword/components/RequestOtpPage').then(
    (module) => ({ default: module.RequestOtpPage })
  )
)

const ResetPasswordPage = React.lazy(() =>
  import('modules/pages/forgotPassword/components/ResetPassword').then(
    (module) => ({ default: module.ResetPasswordPage })
  )
)

export const authRoutes = [
  {
    path: signInPath,
    element: LoginPage,
    protected: false,
    title: 'Login',
    pathType: 0,
    isHideSidebar: true,
    isHideNavbar: true,
  },
  {
    path: forgotPasswordPath,
    element: RequestOtpPage,
    protected: false,
    title: 'Forgot Password',
    pathType: 0,
    isHideSidebar: true,
    isHideNavbar: true,
  },
  {
    path: verifyOtpPath,
    element: OtpVerify,
    protected: false,
    title: 'Verify OTP',
    pathType: 0,
    isHideSidebar: true,
    isHideNavbar: true,
  },
  {
    path: resetPasswordPath,
    element: ResetPasswordPage,
    protected: false,
    title: 'Reset Password',
    pathType: 0,
    isHideSidebar: true,
    isHideNavbar: true,
  },
]
