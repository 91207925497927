import { createAction } from '@reduxjs/toolkit'

export const setUserReportColumns = createAction(
  'userReport/columns',
  (payload: any) => ({
    payload,
  })
)
export const setUserReportFilters = createAction(
  'userReport/setFilters',
  (payload: any) => ({
    payload,
  })
)
export const updateUserReportFilters = createAction(
  'userReport/updateFilter',
  (payload: { dataKey: string; value: any }) => ({
    payload,
  })
)
export const clearUserReportFilters = createAction('userReport/clearFilters')
export const setInitialUserReportFilters = createAction(
  'userReport/setInitialFilters'
)
export const keepOnlyKeysUserReport = createAction('userReport/keepOnlyKeys')
