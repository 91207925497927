import styled, { ThemeProps } from 'styled-components'
import { Theme, screenSizes } from 'styles/theme'

interface ModalBodyProps {
  show: boolean
  customBg?: string
  bodyStyles?: any
}

export const ModelHead = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  position: relative;
  font-family: Poppins;
  color: ${(props: ThemeProps<Theme>) => props.theme.primary};
  h2 {
    margin: 0;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
  }
  img {
    position: absolute;
    height: 17px;
    right: 0;
  }
`

export const ModalBody = styled.div<ModalBodyProps>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  z-index: ${(props) => (props.bodyStyles ? props.bodyStyles.zIndex : '99999')};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${(props) =>
    props.customBg ? `${props.customBg}` : 'rgba(0, 0, 0, 0.8)'};
  @keyframes increasingOpacity {
    0% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }

  animation: 0.3s ease-out 0s 1 increasingOpacity;
`

export const ModalContent = styled.div<any>`
  background: ${(props) => props.theme.modalBg};
  backdrop-filter: blur(10px);
  display: inline-block;
  margin: 0 auto;
  border: 2px solid rgba(255, 255, 255, 0.08);
  border-radius: 16px;
  overflow: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 95vh;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  /* max-width: 90%; */
  display: flex;
  flex-direction: column;
  border: 2px solid rgba(255, 255, 255, 0.08);
  padding: 30px 25px;
`
export const Close = styled.img`
  cursor: pointer;
  height: 17px;
  width: 17px;
  @media (min-width: ${screenSizes.mediaM}px) {
    cursor: pointer;
    margin-right: 0;
  }
`
export const CloseIcon = styled.img`
  cursor: pointer;
  height: 18px !important;
  right: -10px !important;
  top: 20px !important;
  @media (min-width: ${screenSizes.mediaS}px) {
    cursor: pointer;
    margin-right: 0;
    right: 20px !important;
  }
`
