import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  clearUserReportFilters,
  setInitialUserReportFilters,
  setUserReportColumns,
} from '../actions/userReport'
import { filters } from 'modules/pages/userReport/filterConstants'

// Define the type for the action payload
interface ActionPayload {
  column: string
  keepAdding?: boolean
}

// Define the state type, which can be either null or an array of strings
type UserReportState = string[] | null

const dataKeys: string[] = filters.map((filter: any) => filter.dataKey)
const initialState: UserReportState = dataKeys

// Create the reducer with a builder callback
const userReportReducer = createReducer(initialState, (builder) => {
  //@ts-ignore
  builder.addCase(
    setUserReportColumns,
    (state: UserReportState, action: PayloadAction<ActionPayload>) => {
      const { column, keepAdding } = action.payload

      if (state === null) {
        return [column]
      } else {
        if (state?.includes(column)) {
          if (keepAdding) {
            // If keepAdding is true, do not remove the column, just return the state
            return state
          } else {
            const newState = state?.filter((item: string) => item !== column)
            return newState.length > 0 ? newState : null
          }
        } else {
          return [...state, column]
        }
      }
    }
  )

  // Clear all
  //@ts-ignore
  builder.addCase(clearUserReportFilters, () => {
    return ['last_login']
  })
  //set initial values
  //@ts-ignore
  builder.addCase(setInitialUserReportFilters, () => {
    return initialState
  })
})

export default userReportReducer
