import {
  createLaunchpadPagePath,
  launchpadPagePath,
  launchpadPath,
  signInPath,
  viewLaunchpadPath,
} from 'logic/paths'
import React from 'react'

const LaunchpadPage = React.lazy(() =>
  import('modules/pages/launchpad').then((module) => ({
    default: module.LaunchpadPage,
  }))
)
const LaunchpadDetailsPage = React.lazy(() =>
  import('modules/pages/launchpad/LaunchpadDetails').then((module) => ({
    default: module.LaunchpadDetailsPage,
  }))
)
const LaunchpadAsset = React.lazy(() =>
  import('modules/pages/launchpad/components/launchpadAsset').then(
    (module) => ({ default: module.LaunchpadAsset })
  )
)

const CreateLaunchpad = React.lazy(() =>
  import('modules/pages/launchpad/createLaunchpads/CreateLaunchpad').then(
    (module) => ({ default: module.CreateLaunchpad })
  )
)

const commonRoutes = [
  {
    path: launchpadPath,
    element: LaunchpadPage,
    protected: true,
    title: 'Launchpads',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: launchpadPagePath,
    element: LaunchpadDetailsPage,
    protected: true,
    title: 'Launchpads',
    pathType: 0,
    redirect: signInPath,
  },

  {
    path: viewLaunchpadPath,
    element: LaunchpadAsset,
    protected: true,
    title: 'View Launchpad',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: createLaunchpadPagePath,
    element: CreateLaunchpad,
    protected: true,
    title: 'Create Launchpad',
    pathType: 0,
    redirect: signInPath,
  },
]

export const launchpadAdminRoutes = [...commonRoutes]
