import { homePath, rootPath, signInPath } from 'logic/paths'
import { activityAdminRoutes } from './moduleRoutes/activity.route'
import { activityReportAdminRoutes } from './moduleRoutes/activityReport.route'
import { analyticsAdminRoutes } from './moduleRoutes/analytics.route'
import { assetsAdminRoutes } from './moduleRoutes/assets.routes'
import { authRoutes } from './moduleRoutes/auth.route'
import { configureAdminRoutes } from './moduleRoutes/configuration.route'
import { dashboardAdminRoutes } from './moduleRoutes/dashboard.route'
import { gameAdminRoutes } from './moduleRoutes/games.route'
import { launchpadAdminRoutes } from './moduleRoutes/launchpad.route'
import { notificationAdminRoutes } from './moduleRoutes/notifications.route'
import { playerAdminRoutes } from './moduleRoutes/players.route'
import { publisherReportConfigurationRoutes } from './moduleRoutes/publisherReportConfiguration.route'
import { publisherAdminRoutes } from './moduleRoutes/publishers.route'
import { questAdminAdminRoutes } from './moduleRoutes/quest.route'
import { starterPackAdminRoutes } from './moduleRoutes/starterPack.route'
import { userReportAdminRoutes } from './moduleRoutes/userReport.route'
import { webCarouselAdminRoutes } from './moduleRoutes/webCarousel.route'
import React from 'react'
import { RouteDefinition } from 'utils/type'
import { floatingCarouselAdminRoutes } from './moduleRoutes/floatingCarousel.route'

const Dashboard = React.lazy(() =>
  import('modules/pages/dashboard').then((module) => ({
    default: module.Dashboard,
  }))
)

const PageNotFound = React.lazy(() =>
  import('modules/pages/pageNotFound').then((module) => ({
    default: module.PageNotFound,
  }))
)

export const notFoundRoute: RouteDefinition = {
  path: '*',
  element: PageNotFound,
  protected: false,
  isHideSidebar: true,
  isHideNavbar: true,
  title: '',
}

// Routes accessible by Super Admin and sub Admin
export const baseRoutes = [
  {
    path: rootPath,
    element: Dashboard,
    protected: true,
    title: 'Home',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: homePath,
    redirect: rootPath,
  },
  notFoundRoute,
  ...authRoutes,
  ...dashboardAdminRoutes,
  ...questAdminAdminRoutes,
  ...userReportAdminRoutes,
  ...activityReportAdminRoutes,
  ...webCarouselAdminRoutes,
  ...floatingCarouselAdminRoutes,
  ...launchpadAdminRoutes,
  ...notificationAdminRoutes,
  ...activityAdminRoutes,
  ...starterPackAdminRoutes,
  ...gameAdminRoutes,
  ...assetsAdminRoutes,
  ...publisherAdminRoutes,
  ...playerAdminRoutes,
  ...analyticsAdminRoutes,
  ...configureAdminRoutes,
  ...publisherReportConfigurationRoutes,
]
