import { useAppSelector } from 'logic/redux/hooks'
import { ADMIN_ROLES } from 'utils/enums'

type AdminRole =
  | ADMIN_ROLES.SUPER_ADMIN
  | ADMIN_ROLES.SUB_ADMIN
  | ADMIN_ROLES.PUBLISHER
/**
 * Retrieves the admin role from the user's profile details.
 *
 * This function uses the 'useAppSelector' hook to access the Redux state and retrieve the user's profile details.
 * It then extracts the admin role from the profile details and returns it.
 *
 * @returns {AdminRole} The admin role of the user, which can be SUPER_ADMIN, SUB_ADMIN, or PUBLISHER.
 */
export const useAdminRole = (): AdminRole => {
  const { profileDetail } = useAppSelector((state: any) => state.adminReducer)
  const role: AdminRole = profileDetail?.roles[0]

  return role
}
