import {
  companyRequestChangesPath,
  gamePublisherPath,
  publisherCompanyDetailsPath,
  publisherProfilePath,
  signInPath,
} from 'logic/paths'
import React from 'react'

const GamePublisherPage = React.lazy(() =>
  import('modules/pages/gamePublishers').then((module) => ({
    default: module.GamePublisherPage,
  }))
)
const PublisherProfile = React.lazy(() =>
  import('modules/pages/gamePublishers/publisherProfile').then((module) => ({
    default: module.PublisherProfile,
  }))
)
const CompanyRequestChanges = React.lazy(() =>
  import(
    'modules/pages/gamePublishers/companyRequestChanges/CompanyRequestChanges'
  ).then((module) => ({
    default: module.CompanyRequestChanges,
  }))
)
const PublisherCompanyDetails = React.lazy(() =>
  import(
    'modules/pages/gamePublishers/companyRequestChanges/PublisherCompanyDetails'
  ).then((module) => ({
    default: module.PublisherCompanyDetails,
  }))
)

export const publisherAdminRoutes = [
  {
    path: gamePublisherPath,
    element: GamePublisherPage,
    protected: true,
    title: 'Game Publishers',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: publisherProfilePath,
    element: PublisherProfile,
    protected: true,
    title: 'Game Publishers',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: companyRequestChangesPath,
    element: CompanyRequestChanges,
    protected: true,
    title: 'Game Publishers',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: publisherCompanyDetailsPath,
    element: PublisherCompanyDetails,
    protected: true,
    title: 'Game Publishers',
    pathType: 0,
    redirect: signInPath,
  },
]
