import {
  editBannerPath,
  editCarouselPagePath,
  newBannerPath,
  signInPath,
  viewWebCarouselRequestChangesPagePath,
  viewWebCarouselRequestPagePath,
  webCarouselPath,
} from 'logic/paths'
import React from 'react'

const EditCarouselPage = React.lazy(() =>
  import('modules/pages/configurations/webCarouselPages/EditCarouselPage').then(
    (module) => ({
      default: module.EditCarouselPage,
    })
  )
)
const WebCarouselHome = React.lazy(() =>
  import('modules/pages/webCarousel/WebCarousel').then((module) => ({
    default: module.WebCarousel,
  }))
)
const EditCarouselSliderPage = React.lazy(() =>
  import(
    'modules/pages/webCarousel/EditCarouselPage/EditCarouselSliderPage'
  ).then((module) => ({
    default: module.EditCarouselSliderPage,
  }))
)

const WebCarouselRequestDetailsPage = React.lazy(() =>
  import(
    'modules/pages/webCarousel/webCarouselRequestDetailsPage/WebCarouselRequestDetailsPage'
  ).then((module) => ({ default: module.WebCarouselRequestDetailsPage }))
)
const WebCarouselChangesPage = React.lazy(() =>
  import(
    'modules/pages/webCarousel/webCarouselRequestDetailsPage/WebCarouselChangesPage'
  ).then((module) => ({ default: module.WebCarouselChangesPage }))
)

const commonRoutes = [
  {
    path: webCarouselPath,
    element: WebCarouselHome,
    protected: true,
    title: 'Banner Page',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: newBannerPath,
    element: EditCarouselSliderPage,
    protected: true,
    title: 'Banner Page',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: editCarouselPagePath,
    element: EditCarouselPage,
    protected: true,
    title: 'Edit carousel page',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: editBannerPath,
    element: EditCarouselSliderPage,
    protected: true,
    title: 'Edit slider Page',
    pathType: 0,
    redirect: signInPath,
  },

  {
    path: viewWebCarouselRequestChangesPagePath,
    element: WebCarouselChangesPage,
    protected: true,
    title: 'Carousel request details page',
    pathType: 0,
    redirect: signInPath,
  },
]

const adminRoutes = [
  ...commonRoutes,
  {
    path: viewWebCarouselRequestPagePath,
    element: WebCarouselRequestDetailsPage,
    protected: true,
    title: 'Carousel request details page',
    pathType: 0,
    redirect: signInPath,
  },
]

export const webCarouselAdminRoutes = [...adminRoutes]
export const webCarouselPublisherRoutes = [...commonRoutes]
