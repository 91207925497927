import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import {
  clearActivityReportFilters,
  setInitialActivityReportFilters,
  setActivityReportColumns,
} from '../actions/activityReport'
import { filters } from 'modules/pages/activityReport/filterConstants'

// Define the type for the action payload
interface ActionPayload {
  column: string
  keepAdding?: boolean
}

// Define the state type, which can be either null or an array of strings
type ActivityReportState = string[] | null

// Define the initial state with the type ActivityReportState

const dataKeys: string[] = filters.map((filter: any) => filter.dataKey)

const initialState: ActivityReportState = dataKeys

// Create the reducer with a builder callback
const activityReportReducer = createReducer(initialState, (builder) => {
  //@ts-ignore
  builder.addCase(
    setActivityReportColumns,
    (state: ActivityReportState, action: PayloadAction<ActionPayload>) => {
      const { column, keepAdding } = action.payload

      if (state === null) {
        return [column]
      } else {
        if (state?.includes(column)) {
          if (keepAdding) {
            // If keepAdding is true, do not remove the column, just return the state
            return state
          } else {
            const newState = state?.filter((item: string) => item !== column)
            return newState.length > 0 ? newState : null
          }
        } else {
          return [...state, column]
        }
      }
    }
  )

  // Clear all
  //@ts-ignore
  builder.addCase(clearActivityReportFilters, () => {
    return ['event_type_id', 'event_time']
  })
  //set initial values
  //@ts-ignore
  builder.addCase(setInitialActivityReportFilters, () => {
    return initialState
  })
})

export default activityReportReducer
