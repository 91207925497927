import {
  editQuestWithIdPath,
  eventDetailsPath,
  newQuestPath,
  questCreateLogicGroupPath,
  questEditLogicGroupPath,
  questEventDetailsPath,
  questPath,
  questProfilePath,
  signInPath,
  viewQuestPagePath,
  viewQuestRequestChangesPagePath,
  viewQuestRequestPagePath,
} from 'logic/paths'
import React from 'react'

const Quest = React.lazy(() =>
  import('modules/pages/quest').then((module) => ({
    default: module.Quest,
  }))
)

const CreateNewQuest = React.lazy(() =>
  import('modules/pages/quest/createNewQuest').then((module) => ({
    default: module.CreateNewQuest,
  }))
)

const QuestLogicGroupDetailsPage = React.lazy(() =>
  import(
    'modules/pages/quest/pages/questLogicGroupDetailsPage/QuestLogicGroupDetailsPage'
  ).then((module) => ({
    default: module.QuestLogicGroupDetailsPage,
  }))
)

const QuestDetailsPage = React.lazy(() =>
  import('modules/pages/quest/questDetails/QuestDetailsPage').then(
    (module) => ({ default: module.QuestDetailsPage })
  )
)
const CreateLogicGroup = React.lazy(() =>
  import(
    'modules/pages/quest/pages/createLogicGroupPage/CreateLogicGroup'
  ).then((module) => ({ default: module.CreateLogicGroup }))
)
const QuestRequestDetailsPage = React.lazy(() =>
  import(
    'modules/pages/quest/pages/questRequestDetailsPage/QuestRequestDetailsPage'
  ).then((module) => ({ default: module.QuestRequestDetailsPage }))
)

const QuestChangesPage = React.lazy(() =>
  import('modules/pages/quest/pages/questChangesPage/QuestChangesPage').then(
    (module) => ({ default: module.QuestChangesPage })
  )
)
const QuestUserProfile = React.lazy(() =>
  import('modules/pages/quest/questUserProfile/QuestUserProfile').then(
    (module) => ({ default: module.QuestUserProfile })
  )
)
const EventDetailsPage = React.lazy(() =>
  import('modules/pages/quest/questEvents/EventDetailsPage').then((module) => ({
    default: module.EventDetailsPage,
  }))
)

const commonRoutes = [
  {
    path: questPath,
    element: Quest,
    protected: true,
    title: 'Quest',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: viewQuestPagePath,
    element: QuestDetailsPage,
    protected: true,
    title: 'Quest',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: newQuestPath,
    element: CreateNewQuest,
    protected: true,
    title: 'Create New Quest',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: questCreateLogicGroupPath,
    element: CreateLogicGroup,
    protected: true,
    title: 'Create logic group',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: questEditLogicGroupPath,
    element: CreateLogicGroup,
    protected: true,
    title: 'Edit logic group',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: questProfilePath,
    element: QuestUserProfile,
    protected: true,
    title: 'quest user profile',
    pathType: 0,
    redirect: signInPath,
  },
]

export const questAdminAdminRoutes = [
  ...commonRoutes,
  {
    path: editQuestWithIdPath,
    element: CreateNewQuest,
    protected: true,
    title: 'Quest',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: viewQuestRequestPagePath,
    element: QuestRequestDetailsPage,
    protected: true,
    title: 'Quest quest details page',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: viewQuestRequestChangesPagePath,
    element: QuestChangesPage,
    protected: true,
    title: 'Quest quest details page',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: eventDetailsPath,
    element: EventDetailsPage,
    protected: true,
    title: 'Event details Page',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: questEventDetailsPath,
    element: QuestLogicGroupDetailsPage,
    protected: true,
    title: 'Quest Logic group details Page',
    pathType: 0,
    redirect: signInPath,
  },
]

export const questPublisherRoutes = [
  ...commonRoutes,
  {
    path: editQuestWithIdPath,
    element: CreateNewQuest,
    protected: true,
    title: 'Quest',
    pathType: 0,
    redirect: signInPath,
  },
]
