import { withHeader } from 'shared/hocs/withHeader'
import { RouteDefinition } from 'utils/type'
import { baseRoutes } from './baseRoutes'

// Routes accessible by Super Admin and sub Admin
const routes = [...baseRoutes]

export const subAdminRoutes: RouteDefinition[] = [...routes]
  .map((r) => ({
    ...r,
    element: withHeader(r),
  }))
  .concat()
