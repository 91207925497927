import {
  activityCustomReportTablePath,
  activityReportCreateFilterPath,
  activityReportEditFilterPath,
  activityReportFiltersPath,
  activityReportPath,
  activityReportTablePath,
  signInPath,
} from 'logic/paths'
import React from 'react'

const ActivityReportTable = React.lazy(() =>
  import('modules/pages/activityReport/ActivityReportTable').then((module) => ({
    default: module.ActivityReportTable,
  }))
)

const RenderActivityReportFilters = React.lazy(() =>
  import('modules/pages/activityReport/RenderReportFilters').then((module) => ({
    default: module.RenderReportFilters,
  }))
)

const ActivityReportPage = React.lazy(() =>
  import('modules/pages/activityReport/ActivityReport').then((module) => ({
    default: module.ActivityReport,
  }))
)

const commonRoutes = [
  {
    path: activityReportPath,
    element: ActivityReportTable,
    protected: true,
    title: 'Activity Report Page',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: activityReportEditFilterPath,
    element: RenderActivityReportFilters,
    protected: true,
    title: 'Activity Report Filters',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: activityReportCreateFilterPath,
    element: RenderActivityReportFilters,
    protected: true,
    title: 'Activity Report Filters',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: activityCustomReportTablePath,
    element: ActivityReportTable,
    protected: true,
    title: 'User Report Page',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: activityReportTablePath,
    element: ActivityReportTable,
    protected: true,
    title: 'Activity Report Page',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: activityReportFiltersPath,
    element: ActivityReportPage,
    protected: true,
    title: 'User Report Page',
    pathType: 0,
    redirect: signInPath,
  },
]

export const activityReportAdminRoutes = [...commonRoutes]
