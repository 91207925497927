import { createAction } from '@reduxjs/toolkit'

export const setEthPrice = createAction(
  'price/ethPrice',
  (payload: number) => ({
    payload,
  })
)
export const setSolPrice = createAction(
  'price/solPrice',
  (payload: number) => ({
    payload,
  })
)
