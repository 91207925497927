import styled from 'styled-components'
import { headerHeight } from 'styles/theme'

export const FixedHeader = styled.div<any>`
  z-index: 999;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  top: 0;
  background: white;
  position: fixed;
  height: ${headerHeight}px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
  background: ${(props: any) => props.theme.secondary};
`
