import React from 'react'
import { ButtonWrapper, ButtonAlignment } from './style'
import { Row } from 'shared/styled'
import { Text7 } from 'shared/typography'

interface ButtonProps {
  children?: React.ReactNode
  btnType?: string
  align?: string
  justify?: string
  onClick?: any
  isDisabled?: boolean
  className?: string
  fSize?: string
  fSizeMobile?: string
  width?: string
  onHover?: React.MouseEventHandler<HTMLButtonElement>
  onHoverLeave?: React.MouseEventHandler<HTMLButtonElement>
  type?: any
  height?: string
  icon?: React.ReactElement
  disableCircularLoadAnimation?: boolean
  padding?: string
  noMinHeight?: boolean
  noMinWidth?: boolean
  iconFirst?: boolean
  lineHeight?: string
  iconGap?: string
  testId?: string
}

export const Button = (props: ButtonProps) => {
  const {
    children,
    onHover,
    onHoverLeave,
    btnType,
    fSize,
    fSizeMobile,
    align,
    justify,
    onClick,
    isDisabled,
    className,
    width,
    height,
    type,
    icon,
    disableCircularLoadAnimation,
    noMinHeight,
    noMinWidth,
    padding,
    iconFirst,
    lineHeight,
    iconGap,
    testId,
  } = props
  return (
    <ButtonAlignment
      width={width}
      className={className}
      justify={justify}
      align={align}
      data-testid={testId}
    >
      <ButtonWrapper
        fSize={fSize}
        fSizeMobile={fSizeMobile}
        disabled={isDisabled}
        onClick={onClick}
        btnType={btnType}
        onMouseOver={onHover}
        onMouseLeave={onHoverLeave}
        width={width}
        type={type}
        height={height}
        lineHeight={lineHeight}
        disableCircularLoadAnimation={disableCircularLoadAnimation}
        noMinHeight={noMinHeight}
        noMinWidth={noMinWidth}
        padding={padding}
        data-testid='button'
      >
        {icon ? (
          <Row gap={iconGap} justify='center' align='center'>
            {iconFirst ? (
              <>
                {icon}
                <Text7>{children}</Text7>
              </>
            ) : (
              <>
                <Text7>{children}</Text7>
                {icon}
              </>
            )}
          </Row>
        ) : (
          <Text7>{children}</Text7>
        )}
      </ButtonWrapper>
    </ButtonAlignment>
  )
}
