import {
  editNotificationPath,
  notificationsPath,
  signInPath,
} from 'logic/paths'
import React from 'react'

const NotificationSettings = React.lazy(() =>
  import('modules/pages/notificationSettings').then((module) => ({
    default: module.NotificationSettings,
  }))
)
const EditNotificationSetting = React.lazy(() =>
  import('modules/pages/editNotificationSetting').then((module) => ({
    default: module.EditNotificationSetting,
  }))
)

export const notificationAdminRoutes = [
  {
    path: notificationsPath,
    element: NotificationSettings,
    protected: true,
    title: 'Configure Notifications',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: editNotificationPath,
    element: EditNotificationSetting,
    protected: true,
    title: 'Edit Notifications',
    pathType: 0,
    redirect: signInPath,
  },
]
