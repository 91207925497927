import { createReducer } from '@reduxjs/toolkit'
import { I_PriceState } from 'utils/type'
import { setEthPrice, setSolPrice } from '../actions/price'

const initialState: I_PriceState = {
  ethPrice: 0,
  solPrice: 0,
}

const priceReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setEthPrice, (state, action) => {
      state.ethPrice = action.payload
    })
    .addCase(setSolPrice, (state, action) => {
      state.solPrice = action.payload
    })
})

export default priceReducer
