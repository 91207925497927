import styled from 'styled-components'
import { headerHeight, screenSizes } from 'styles/theme'

interface I_SectionProps {
  isSecondaryNav?: boolean
}
export const Section = styled.div<I_SectionProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow: hidden; */
  background: ${(props) => props.theme.secondary};
`
interface IWrapper {
  withNoMaxWidth?: boolean
  isHideSidebar?: boolean
  isDisablePadding?: boolean
}

export const Body = styled.div<IWrapper>`
  min-height: calc(100vh - ${headerHeight + 118}px);
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: ${screenSizes.mediaL}px) {
    // justify-content: center;
  }
  position: relative;
`

export const Wrapper = styled.div<IWrapper>`
  display: flex;
  align-items: start;
  position: relative;
  padding: 0 36px 0 0;
  width: 100%;
  /* min-height: 100vh; */
  height: 100%;
  /* overflow: scroll; */
  @media (max-width: ${screenSizes.mediaL}px) {
    .publisherDesktopSidebar {
      display: none;
    }
  }
`

export const Main = styled.div`
  margin-top: ${headerHeight}px;
  display: flex;
`
