import { signInPath } from 'logic/paths'
import { clearProfileDetail } from 'logic/redux/actions'
import { isLoggedIn } from 'logic/redux/actions/login'
import { useAppDispatch } from 'logic/redux/hooks'
import { useNavigate } from 'react-router-dom'
import { clearStorage, serverConfig } from 'utils'
import { ADMIN_ROLES } from 'utils/enums'
import { useAdminRole } from './useAdminRole'

// Assuming these are the actions and the path you need

const useLogout = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const adminRole = useAdminRole()

  const logout = () => {
    // Clear local and session storage
    clearStorage()

    // Dispatch Redux actions to update the state
    dispatch(clearProfileDetail({}))
    dispatch(isLoggedIn())

    if (adminRole === ADMIN_ROLES.PUBLISHER) {
      if (serverConfig?.userMktFeBaseUrl)
        window.open(`${serverConfig?.userMktFeBaseUrl}?logout=true`, '_blank')
      window.close()
    } else {
      // Navigate to the sign-in page
      navigate(signInPath)
    }
  }

  return logout
}

export default useLogout
