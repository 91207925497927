import { Row } from 'shared/styled'
import styled, { ThemeProps } from 'styled-components'
import { Theme, screenSizes } from 'styles/theme'

export const SidebarWrapper = styled.div`
  border-right: 1px solid ${(props: any) => props.theme.borderColor};
  min-width: 250px;
  min-height: 100vh;
  overflow: auto;
  position: sticky;
  top: 0px;
  clip-path: inset(0px);
  ::-webkit-scrollbar {
    width: 10px;
    background-color: ${(props: ThemeProps<Theme>) => props.theme.black};
    border-radius: 4px;
  }
`

export const SidebarContainer = styled.nav`
  height: 70vh;
  overflow-y: auto;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 14px;
  padding: 60px 16px 47px;

  .subContainer {
    margin-left: 12px;
  }
  .active-route {
    background-color: ${(props: any) => props.theme.primary};
    border-radius: 8px;
    transition: background-color 0.5s linear;
  }

  .inactive-route:hover {
    div {
      gap: 15px;
      transition: all 0.3s ease-in;
    }

    p {
      // font-weight: 600;
      // font-size: 18px;
      transition: all 0.1s ease-in;
      white-space: nowrap;
      color: ${(props) => props.theme.primary};
    }

    .strokeIcon > path {
      stroke: ${(props) => props.theme.primary};
    }

    .questIcon > path {
      fill: ${(props) => props.theme.primary};
    }

    .fillIcon > path {
      fill: ${(props) => props.theme.primary};
    }
  }

  .navLink {
    width: 100%;
  }
  ${Row} {
    width: 100%;
    padding: 12px;
  }
  @media (min-width: ${screenSizes.mediaL}px) {
    min-height: calc(100vh - 200px);

    .active-route {
      p {
        color: ${(props: any) => props.theme.secondary} !important;
      }

      .strokeIcon > path {
        stroke: ${(props) => props.theme.secondary};
      }
      .fillIcon > path {
        fill: ${(props) => props.theme.secondary};
      }

      .questIcon > path {
        fill: ${(props) => props.theme.secondary};
      }
    }
  }

  @media (min-width: ${screenSizes.mediaCL}px) {
    display: flex;
    flex-grow: 1;
    min-height: calc(100vh - 180px);

    .active-route {
      p {
        color: ${(props: any) => props.theme.secondary} !important;
      }

      .strokeIcon > path {
        stroke: ${(props) => props.theme.secondary};
      }
      .fillIcon > path {
        fill: ${(props) => props.theme.secondary};
      }

      .questIcon > path {
        fill: ${(props) => props.theme.secondary};
      }
    }
  }
  @media (max-height: 820px) {
    gap: 10px;
    padding: 40px 16px 30px;
  }

  @media (min-width: ${screenSizes.mediaL}px) {
    .subContainer {
      margin-left: 0;
    }
  }
`
export const LogoContainer = styled.div`
  display: none;
  justify-content: start;
  margin: 0 16px;
  align-items: center;
  :hover {
    cursor: pointer;
  }
  @media (min-width: ${screenSizes.mediaL}px) {
    display: flex;
  }
`
export const LogoWrapper = styled.div`
  padding: 24px 0px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
`
export const AdminDetailsContainer = styled.div`
  display: flex;
  margin: 0px 16px 24px;
  padding-top: 25px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  gap: 12px;
  #logoutText:hover {
    font-size: 16px;
    transition: font-size 0.3s linear;
    cursor: pointer;
  }
`
export const AdminAvatar = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50%;
`

export const SubSidebarContainer = styled.nav`
  overflow-y: auto;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 14px;
  width: 100%;

  .active-route {
    background-color: ${(props: any) => props.theme.secondary} !important;
    border-radius: 8px;
    transition: background-color 0.5s linear;
    max-width: 170px;

    .subItem {
      transition: all 0.1s ease-in;
      white-space: nowrap;
      color: ${(props) => props.theme.primary};
    }
  }

  .nested-sidebar {
    cursor: pointer;
  }

  .inactive-route:hover {
    div {
      gap: 15px;
      transition: all 0.3s ease-in;
    }

    p {
      transition: all 0.1s ease-in;
      white-space: nowrap;
      color: ${(props) => props.theme.primary};
    }

    .strokeIcon > path {
      stroke: ${(props) => props.theme.primary};
    }

    .questIcon > path {
      fill: ${(props) => props.theme.primary};
    }

    .fillIcon > path {
      fill: ${(props) => props.theme.primary};
    }
  }

  .navLink {
    width: 100%;
  }

  @media (min-width: ${screenSizes.mediaL}px) {
    .active-route {
      background-color: ${(props: any) => props.theme.primary} !important;
      border-radius: 8px;
      transition: background-color 0.5s linear;
    }
  }
`
