import { PayloadAction, createReducer } from '@reduxjs/toolkit'
import {
  clearActivityReportFilters,
  keepOnlyKeysActivityReport,
  setInitialActivityReportFilters,
  setActivityReportFilters,
  updateActivityReportFilters,
} from '../actions/activityReport'
import { filters } from 'modules/pages/activityReport/filterConstants'

// Define the type for the filter value
interface FilterValue {
  selected: boolean
  values?: string | boolean | { min: number; max: number; currency?: string }
}

// Define the state type, which is an object with string keys and FilterValue value
interface ActivityReportFiltersState {
  [key: string]: FilterValue
}

type ActivityReportState = ActivityReportFiltersState | null

const result = filters.reduce((acc, filter) => {
  const { dataKey } = filter
  if (dataKey) {
    // @ts-ignore
    acc[dataKey] = { selected: true, values: null }
  }
  return acc
}, {})

// Define the initial state with the type ActivityReportState
const initialState: ActivityReportState = result

const activityReportFilterReducer = createReducer(initialState, (builder) => {
  builder.addCase(setActivityReportFilters, (state: any, action) => {
    if (state === null) {
      return {
        ...action.payload,
      }
    } else if (state) {
      const newState = { ...state }

      // Iterate through the payload properties
      for (const key in action.payload) {
        if (action.payload.hasOwnProperty(key)) {
          const payloadItem = action.payload[key]
          if (payloadItem.selected === false) {
            // Remove the property from the state if selected is false
            delete newState[key]
          } else {
            // Otherwise, merge the property into the state
            newState[key] = payloadItem
          }
        }
      }

      return newState
    }
    return state
  })
  //@ts-ignore
  builder.addCase(
    updateActivityReportFilters,
    (
      state: ActivityReportState | null,
      action: PayloadAction<{ dataKey: string; values: any }>
    ) => {
      const { dataKey, values } = action.payload

      if (state?.[dataKey]) {
        // If dataKey exists, update the value
        state[dataKey] = {
          ...state[dataKey],
          values: values,
        }
      } else {
        if (state === null) {
          // If dataKey does not exist, add a new entry
          return {
            [dataKey]: {
              selected: true,
              values: values,
            },
          }
        } else {
          state[dataKey] = {
            selected: true,
            values: values,
          }
        }
      }

      // Check if dataKey is 'currency_type' to update the currency in other fields
      if (dataKey === 'currency_type') {
        const newCurrency = values
        Object.keys(state).forEach((key) => {
          //@ts-ignore
          if (key !== 'currency_type' && state[key]?.values?.currency) {
            //@ts-ignore
            state[key].values.currency = newCurrency
          }
        })
      }
    }
  )

  // Add case for clearActivityReportFilters
  //@ts-ignore
  builder.addCase(clearActivityReportFilters, () => {
    return { event_type_id: { selected: true }, event_time: { selected: true } }
  })

  //@ts-ignore
  builder.addCase(setInitialActivityReportFilters, () => {
    return initialState
  })
  builder.addCase(keepOnlyKeysActivityReport, (state: any, action: any) => {
    console.log('state', state, action)

    const keysToKeep = action.payload
    Object.keys(state).forEach((key) => {
      if (!keysToKeep.includes(key)) {
        delete state[key]
      }
    })
  })
})

export default activityReportFilterReducer
