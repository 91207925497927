import { createReducer } from '@reduxjs/toolkit'
import { I_WebCarouselTabCount } from 'utils/type'
import { setWebCarouselTabsCount } from '../actions/webCarouselActions'

const initialState: I_WebCarouselTabCount = {
  draft_count: 0,
  game_page_slider_count: 0,
  request_count: 0,
  static_page_slider_count: 0,
}

const webCarouselReducer = createReducer(initialState, (builder) => {
  builder.addCase(setWebCarouselTabsCount, (state, action) => {
    const { payload } = action

    return {
      ...state,
      ...payload,
    }
  })
})

export default webCarouselReducer
