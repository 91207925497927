import { Navbar } from 'modules/app/navbar'
import { FixedHeader } from './style'

export const AdminDashHeader = () => {
  return (
    <FixedHeader data-testid='fixedHeader'>
      <Navbar />
    </FixedHeader>
  )
}
