import { LottieAnimation } from './LottieAnimation'

interface LottieAnimationProps {
  className?: string
  path: string
  containerStyle: 'rounded' | 'default'
}

export const SuccessLottieAnimation = (
  props: Omit<LottieAnimationProps, 'containerStyle'>
) => <LottieAnimation {...props} containerStyle='default' />
