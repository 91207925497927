import styled from 'styled-components'
import { maxWidth, screenSizes } from 'styles/theme'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  height: inherit;
`
export const Navigation = styled.nav`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 33px;
  a {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    font-size: 13px;
    color: ${(props) => props.theme.primaryText};
  }
  @media (max-width: ${screenSizes.mediaS}px) {
    a {
      font-size: 22px !important;
      line-height: 26px;
    }
  }
  @media (max-width: ${screenSizes.mediaCL}px) {
    gap: 8px;
  }
  .publisherMobileSidebar {
    border: none;
    min-height: unset;
    padding: 0;
    padding-left: 2px;
    nav {
      padding: 0;
      .strokeIcon > path {
        stroke: ${(props) => props.theme.secondary};
      }
      .fillIcon > path {
        fill: ${(props) => props.theme.secondary};
      }
    }

    @media (max-width: ${screenSizes.mediaL}px) {
      .active-route {
        color: ${(props: any) => props.theme.primary} !important;
      }

  }
  .navIcons:hover {
    transform: scale(1.2);
    transition: all 0.3s ease-in;
  }
  .pathNavItem:hover {
    /* font-size: 15px; */
    color: ${(props) => props.theme.primary};
    /* font-weight: 600; */
    transform: translateY(-2px);
    transition: all 0.3s ease-in;
  }
  @media (max-width: ${screenSizes.mediaL}px) {
    .navIcons > path {
      fill: ${(props) => props.theme.secondary};
      stroke: ${(props) => props.theme.secondary};
    }
    .navLink {
      padding-left: 12px;
    }

    .active-route {
      background-color: ${(props: any) => props.theme.secondary};
      border-radius: 8px;
      transition: background-color 0.5s linear;
      div {
        .strokeIcon > path {
          stroke: ${(props) => props.theme.primary};
        }

        .questIcon > path {
          fill: ${(props) => props.theme.primary};
        }

        .fillIcon > path {
          fill: ${(props) => props.theme.primary};
        }
        p {
          color: ${(props) => props.theme.primary};
        }
      }
    }

    .inactive-route:hover {
      background: ${(props) => props.theme.secondary};
      border-radius: 8px;
      div {
        gap: 15px;
        transition: all 0.3s ease-in;
      }

      p {
        transition: all 0.1s ease-in;
        white-space: nowrap;
        color: ${(props) => props.theme.primary};
      }

      .strokeIcon > path {
        stroke: ${(props) => props.theme.primary};
      }

      .questIcon > path {
        fill: ${(props) => props.theme.primary};
      }

      .fillIcon > path {
        fill: ${(props) => props.theme.primary};
      }
    }
  }
  @media (min-width: ${screenSizes.mediaL}px) {
    max-width: calc(100vw - 350px);
  }
  @media (min-width: ${screenSizes.mediaLX}px) {
    max-width: 1450px;
  }
`

export const NavContainer = styled.nav`
  display: flex;
  gap: 30px;
  max-width: ${maxWidth}px;
  width: 100%;
  .burger {
    display: none;
  }
  @media (max-width: ${screenSizes.mediaL}px) {
    .desktopNavigation {
      display: none;
    }
    .burger {
      display: block;
      :hover {
        cursor: pointer;
      }
    }
  }
  .mobileNavigation {
    flex-direction: column;
    align-items: start;
    gap: 20px;
    padding: 40px 0;
    p,
    a {
      color: #fff;
    }
  }
`
export const MobileNavWrapper = styled.div`
  background: ${(props) => props.theme.primary};
  position: absolute;
  height: 96vh;
  width: 97%;
  padding: 20px 20px;
  right: 10px;

  border-radius: 10px;
  z-index: 999;
  svg.closeNav {
    position: absolute;
    right: 25px;
    path {
      fill: ${(props) => props.theme.secondary};
      stroke: none;
    }
  }
  @media (min-width: ${screenSizes.mediaS}px) {
    max-width: 350px;
    padding: 20px 20px;
    height: 98vh;
    width: 100%;
    right: 15px;
  }
  @media (min-width: ${screenSizes.mediaL + 1}px) {
    display: none;
  }
`

export const NavWrapper = styled.div`
  gap: 85px;
  display: flex;
  position: sticky;
  top: 0;
  backdrop-filter: blur(8px);
  background: ${(props: any) => props.theme.secondary};
  a {
    color: ${(props: any) => props.theme.primaryText};
  }
  .active-route {
    color: ${(props: any) => props.theme.primary} !important;
  }
  z-index: 999;
  height: 100%;
  padding: 0px 36px;
  @media (min-width: ${screenSizes.mediaXXL}px) {
    align-items: center;
    display: flex;
    flex-wrap: inherit;
    justify-content: space-between;
  }
  @media (max-width: ${screenSizes.mediaXL}px) {
    padding: 0px 36px;
  }
  @media (max-width: ${screenSizes.mediaCL}px) {
    gap: 25px;
  }
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

export const PathContainer = styled.div`
  display: flex;
  .pathNavLink {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
