import { createAction } from '@reduxjs/toolkit'

export const setActivityReportColumns = createAction(
  'activityReport/columns',
  (payload: any) => ({
    payload,
  })
)
export const setActivityReportFilters = createAction(
  'activityReport/setFilters',
  (payload: any) => ({
    payload,
  })
)
export const updateActivityReportFilters = createAction(
  'activityReport/updateFilter',
  (payload: { dataKey: string; values: any }) => ({
    payload,
  })
)
export const clearActivityReportFilters = createAction(
  'activityReport/clearFilters'
)
export const setInitialActivityReportFilters = createAction(
  'activityReport/setInitialFilters'
)
export const keepOnlyKeysActivityReport = createAction(
  'activityReport/keepOnlyKeys'
)
