import { NavLink, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

import * as Styles from '../style'

import { Sidebar } from 'modules/app/sidebar'
import { BoldText3 } from 'shared/typography'
import { pathConfig, rootPath } from 'logic/paths'
import { convertString } from 'utils'

interface I_NavigationProps {
  className?: string
  isMobile?: boolean
}
export const AdminNavigation = ({ className, isMobile }: I_NavigationProps) => {
  // pathConfig
  const [pathArray, setPathArray] = useState<any>([])
  const location = useLocation()
  const currentPath = location.pathname
  const renderPath = () => {
    const allPaths: any = currentPath.split('/')
    let filteredPaths: any = [
      {
        key: 'dashboard',
        path: rootPath,
      },
    ]
    for (let i = 1; i < allPaths.length; i++) {
      if (pathConfig[allPaths[i]]) {
        filteredPaths.push({
          key: allPaths[i],
          path: pathConfig[allPaths[i]],
        })
      } else {
        filteredPaths.push({
          key: allPaths[i],
          path: '',
        })
        break
      }
    }

    setPathArray(filteredPaths)
  }
  useEffect(() => {
    renderPath()
  }, [currentPath]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Styles.Navigation className={className}>
      <Styles.PathContainer>
        {pathArray.length &&
          pathArray.map((el: any, idx: any) => {
            return el.path && idx < pathArray?.length - 1 ? (
              <NavLink to={el.path} key={el.key} className='pathNavLink'>
                <BoldText3 className='pathNavItem'>
                  {convertString(el.key)}
                </BoldText3>
                <pre> / </pre>
              </NavLink>
            ) : (
              <BoldText3 key={el.key}>{convertString(el.key)}</BoldText3>
            )
          })}
      </Styles.PathContainer>

      {isMobile && <Sidebar className='publisherMobileSidebar' />}
    </Styles.Navigation>
  )
}
