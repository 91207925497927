import { memo, useEffect, useRef } from 'react'
import lottie from 'lottie-web'
import { RoundedAnimationContainer, AnimationContainer } from './style'

interface LottieAnimationProps {
  className?: string
  path: string
  containerStyle: 'rounded' | 'default'
}

export const LottieAnimation = memo(
  ({ className, path, containerStyle }: LottieAnimationProps) => {
    const animationRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      let animationInstance: any = null

      const loadAnimation = async () => {
        try {
          animationInstance = lottie.loadAnimation({
            container: animationRef.current!,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path,
          })
        } catch (error) {
          console.error('Error loading animation:', error)
        }
      }

      loadAnimation()

      return () => {
        if (animationInstance) {
          animationInstance.destroy()
          animationInstance = null
        }
      }
    }, [path])

    const ContainerComponent =
      containerStyle === 'rounded'
        ? RoundedAnimationContainer
        : AnimationContainer

    return (
      <ContainerComponent
        data-testid='animation-container'
        className={className}
        ref={animationRef}
      />
    )
  }
)
