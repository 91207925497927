import {
  editFloatingBannerPath,
  editFloatingCarouselPagePath,
  newFloatingBannerPath,
  signInPath,
  webFloatingCarouselPath,
} from 'logic/paths'
import React from 'react'

const EditFloatingCarouselPage = React.lazy(() =>
  import(
    'modules/pages/configurations/webCarouselPages/EditFloatingCarouselPage'
  ).then((module) => ({
    default: module.EditFloatingCarouselPage,
  }))
)
const FloatingCarouselHome = React.lazy(() =>
  import('modules/pages/floatingWebCarousel/FloatingCarouselHome').then(
    (module) => ({
      default: module.FloatingCarouselHome,
    })
  )
)
const EditCarouselSliderPage = React.lazy(() =>
  import(
    'modules/pages/floatingWebCarousel/EditCarouselPage/EditCarouselSliderPage'
  ).then((module) => ({
    default: module.EditCarouselSliderPage,
  }))
)

const commonRoutes = [
  {
    path: webFloatingCarouselPath,
    element: FloatingCarouselHome,
    protected: true,
    title: 'Banner Page',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: newFloatingBannerPath,
    element: EditCarouselSliderPage,
    protected: true,
    title: 'Banner Page',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: editFloatingCarouselPagePath,
    element: EditFloatingCarouselPage,
    protected: true,
    title: 'Edit carousel page',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: editFloatingBannerPath,
    element: EditCarouselSliderPage,
    protected: true,
    title: 'Edit slider Page',
    pathType: 0,
    redirect: signInPath,
  },
]

export const floatingCarouselAdminRoutes = [...commonRoutes]
