import { activityPath, signInPath } from 'logic/paths'
import React from 'react'

const ActivityPage = React.lazy(() =>
  import('modules/pages/activity').then((module) => ({
    default: module.ActivityPage,
  }))
)

export const activityAdminRoutes = [
  {
    path: activityPath,
    element: ActivityPage,
    protected: true,
    title: 'Activity',
    pathType: 0,
    redirect: signInPath,
  },
]
