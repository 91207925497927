import { Suspense } from 'react'
import { Navigate, Route, Routes, RoutesProps } from 'react-router-dom'

import { Spinner } from 'shared/spinner/Spinner'
import { GlobalLoaderContainer, Row } from 'shared/styled'

import { RouteDefinition } from 'utils/type'
import { superAdminRoutes } from './superAdminRoutes'

import {
  forgotPasswordPath,
  homePath,
  resetPasswordPath,
  signInPath,
  verifyOtpPath,
} from 'logic/paths'
import { useAdminRole } from 'shared/hooks/useAdminRole'
import styled from 'styled-components'
import { getLocalJwt } from 'utils'
import { ADMIN_ROLES } from 'utils/enums'
import { publisherRoutes } from './publisherRoutes'
import { subAdminRoutes } from './subAdminRoutes'

interface Props {
  initialLoad: boolean
}

const disabledWhenLogin = [
  signInPath,
  forgotPasswordPath,
  verifyOtpPath,
  resetPasswordPath,
]

export const getAdminRoutes = (adminRole: string) => {
  switch (adminRole) {
    case ADMIN_ROLES.SUPER_ADMIN:
      return superAdminRoutes
    case ADMIN_ROLES.SUB_ADMIN:
      return subAdminRoutes
    case ADMIN_ROLES.PUBLISHER:
      return publisherRoutes

    default:
      return subAdminRoutes
  }
}

function getRouteRenderWithAuth(isLoggedIn: boolean, route: RouteDefinition) {
  if (isLoggedIn === route.protected || !route.redirect) {
    const RouteComponent = route.requires
      ? route.requires(route.element)
      : route.element
    if (disabledWhenLogin.includes(route.path) && isLoggedIn) {
      return { element: <Navigate replace to={homePath} /> }
    } else return { element: <RouteComponent /> }
  } else {
    return { element: <Navigate replace to={route.redirect} /> }
  }
}

export const RoutesComponent: React.FC<Props & RoutesProps> = (props) => {
  const userLoggedIn = Boolean(getLocalJwt())
  const adminRole = useAdminRole()

  if (props.initialLoad) {
    return (
      <GlobalLoaderContainer>
        <Spinner />
      </GlobalLoaderContainer>
    )
  }

  const mapRoutes = (route: RouteDefinition, i: number) => {
    const render = getRouteRenderWithAuth(userLoggedIn, route)
    return <Route key={i} path={route.path} {...render} />
  }
  return (
    <Suspense
      fallback={
        <SuspenseLoaderWrap justify='center' align='center'>
          <Spinner />
        </SuspenseLoaderWrap>
      }
    >
      <Routes>{getAdminRoutes(adminRole).map(mapRoutes)}</Routes>
    </Suspense>
  )
}

const SuspenseLoaderWrap = styled(Row)`
  height: 100vh;
`
