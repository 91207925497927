import { Toaster } from 'react-hot-toast'
import { withTheme } from 'styled-components'

export const CustomToaster = withTheme((props) => {
  const { theme } = props
  return (
    <Toaster
      toastOptions={{
        className: '',
        style: {
          padding: '20px',
          // color: theme.error,
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: ' 21px',
          zIndex: 9999999999999,
        },
        error: {
          style: {
            background: theme.secondary,
            color: theme.error,
          },
        },
        success: {
          style: {
            background: theme.secondary,
            color: theme.liveText,
          },
        },
      }}
      containerStyle={{
        // position: 'absolute',
        top: '75px',
        right: '20px',
        zIndex: 999999999999,
      }}
      position='top-right'
      reverseOrder={false}
    />
  )
})
