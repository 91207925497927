import DownloadWrapper from 'DownloadWrapper'
import { rootPath } from 'logic/paths'
import { setProfileDetail, setProfileDetailLoading } from 'logic/redux/actions'
import { isLoggedIn } from 'logic/redux/actions/login'
import { setEthPrice, setSolPrice } from 'logic/redux/actions/price'
import { useAppDispatch, useAppSelector } from 'logic/redux/hooks'
import { RoutesComponent } from 'modules/app/routes/Routes'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useLogout from 'shared/hooks/useLogout'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from 'styles/globalStyle'
import { Themes, getTheme } from 'styles/theme'
import { fetchChainPrice } from 'utils/apis'
import { GetAdminProfileData } from 'utils/apis/dashboard'
import './App.css'
import { ls } from 'dev/secureLS'

export const currentTheme = {
  ...getTheme(Themes.BASIC),
  selected: Themes.BASIC,
}

function App() {
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector((state) => state.adminReducer)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token') ?? ''
  const isPublisher = searchParams.get('ispublisher')

  const logout = useLogout()

  useEffect(() => {
    const handleLogout = () => logout()
    window.addEventListener('logoutEvent', handleLogout)
    return () => window.removeEventListener('logoutEvent', handleLogout)
  }, [logout])

  const init = async () => {
    try {
      dispatch(setProfileDetailLoading(true))
      const data = await GetAdminProfileData(token)

      if (data) {
        const publisherData = {
          token: token,
          ...data,
        }

        ls.set('USER_DETAILS', JSON.stringify({ ...publisherData }))

        ls.set('userAccessToken', token)
        ls.set('email', publisherData?.email)
        dispatch(setProfileDetail(publisherData))
        navigate(rootPath)
      }
    } catch (error) {
    } finally {
      dispatch(setProfileDetailLoading(false))
    }
  }

  useEffect(() => {
    if (token && isPublisher) init()
  }, [])

  useEffect(() => {
    if (!isPublisher) dispatch(isLoggedIn())
  }, [isPublisher])

  const getEthPrice = async () => {
    const res = await fetchChainPrice('ethereum')
    const ethDollarPrice = Number(res?.data?.ETH?.[0]?.quote?.USD?.price ?? 0)
    dispatch(setEthPrice(ethDollarPrice))
  }
  const getSolPrice = async () => {
    const res = await fetchChainPrice('solana')
    const solDollarPrice = Number(res?.data?.SOL[0].quote.USD.price ?? 0)
    dispatch(setSolPrice(solDollarPrice))
  }
  useEffect(() => {
    getEthPrice()
    getSolPrice()
    const interval = setInterval(() => {
      getEthPrice()
      getSolPrice()
    }, 120000)

    return () => clearInterval(interval)
  }, [])

  return (
    <ThemeProvider theme={currentTheme}>
      <DownloadWrapper>
        <GlobalStyle />
        <RoutesComponent initialLoad={loading} />
      </DownloadWrapper>
    </ThemeProvider>
  )
}

export default App
