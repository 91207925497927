import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { I_Profile } from 'utils/type'

export const setProfileDetail = createAsyncThunk(
  'users/profileDetail',
  async (payload: I_Profile, { dispatch }) => {
    return payload
  }
)

export const setProfileDetailLoading = createAction<boolean>(
  'users/profileDetailLoading'
)

export const clearProfileDetail = createAction<object>(
  'users/clearProfileDetail'
)
