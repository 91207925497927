import React from 'react'
import { analyticsPath, signInPath } from 'logic/paths'

const AnalyticsPage = React.lazy(() =>
  import('modules/pages/analytics/AnalyticsPage').then((module) => ({
    default: module.AnalyticsPage,
  }))
)

export const analyticsAdminRoutes = [
  {
    path: analyticsPath,
    element: AnalyticsPage,
    protected: true,
    title: 'Analytics Page',
    pathType: 0,
    redirect: signInPath,
  },
]
