export const DEBOUNCE_TIME = 300 // in miliseconds
export const PAGE_LIMIT = 10
export const GRAPH_TOOLTIP_PAGE_LIMIT = 5

interface I_SortItem {
  value?: string
  name?: string
}
export const sortOptionList: I_SortItem[] = [
  { name: 'Recently Joined', value: 'recently-joined' },
  { name: 'Highest Assets', value: 'highest-assets-hold' },
  { name: 'Lowest Assets', value: 'lowest-assets-hold' },
  { name: 'Top Players', value: 'top-players' },
]

export const WalletDisableOptions = [
  { label: 'Violation of community guidelines or terms of service', type: '1' },
  { label: 'Spamming or engaging in fraudulent activities', type: '2' },
  { label: 'Breaching security or privacy policies', type: '3' },
  {
    label: 'Disruptive behavior or negative impact on user experience',
    type: '4',
  },
  { label: 'Engaging in illegal or unsafe activities', type: '5' },
  { label: 'Other', type: '6' },
]

export const sortStatusOptions: I_SortItem[] = [
  { name: 'Banned', value: 'Ban' },
  { name: 'Blocked', value: 'Blocked' },
  { name: 'All', value: '' },
]

export const userOptionList: I_SortItem[] = [
  { name: 'Players', value: 'players' },
  { name: 'Publishers', value: 'publishers' },
]

export const timeOptionsList = [
  { name: '12 Hours', value: '12-hours' },
  { name: '1 Day', value: '1-days' },
  { name: '3 Days', value: '3-days' },
  { name: '7 Days', value: '7-days' },
  { name: '1 Month', value: '1-months' },
  { name: 'Custom', value: 'custom' },
]

export const BanReasonsOption = [
  { label: 'Violation of community guidelines or terms of service', type: '1' },
  { label: 'Spamming or engaging in fraudulent activities', type: '2' },
  { label: 'Breaching security or privacy policies', type: '3' },
  {
    label: 'Disruptive behavior or negative impact on user experience',
    type: '4',
  },
  { label: 'Engaging in illegal or unsafe activities', type: '5' },
  { label: 'Other', type: '6' },
]
export const DeleteUserOption = [
  { label: 'Pending Transactions', type: '1' },
  { label: 'Unresolved Issues', type: '2' },
  { label: 'Incomplete Request', type: '3' },
  { label: 'Account Under Review', type: '4' },
  { label: 'Regulatory Retention', type: '5' },
  { label: 'Suspicious Activity Detected', type: '6' },
  { label: 'Outstanding Balances', type: '7' },
  { label: 'Legal Hold Required', type: '8' },
  { label: 'Other', type: '9' },
]
export const UnbanReasonsOption = [
  {
    label: 'Issue Resolved',
    type: '1',
  },
  {
    label: 'Appeal Approved',
    type: '2',
  },
  {
    label: 'Temporary Block Expired',
    type: '3',
  },
  {
    label: 'Reinstated After Review',
    type: '4',
  },
  {
    label: 'First-Time Violation',
    type: '5',
  },
  { label: 'Account Recovery', type: '6' },
  { label: 'Error Correction', type: '7' },
  { label: 'Other', type: '8' },
]

export interface I_BlockUserCard {
  handleClose: Function
  handleBlockCall: Function
}
export interface I_BanUserCard {
  handleClose: Function
  handleBanCall: Function
  isDelete?: boolean
}

export interface I_UnBanUserCard {
  handleClose: Function
  handleUnBanCall: Function
}

export interface I_UnBlockUserCard {
  handleClose: Function
  handleUnBlockCall: Function
}
