import { playerProfilePath, playersPath, signInPath } from 'logic/paths'
import React from 'react'

const GamePlayersPage = React.lazy(() =>
  import('modules/pages/gamePlayers').then((module) => ({
    default: module.GamePlayersPage,
  }))
)
const PlayerProfile = React.lazy(() =>
  import('modules/pages/gamePlayers/playerProfile').then((module) => ({
    default: module.PlayerProfile,
  }))
)

export const playerAdminRoutes = [
  {
    path: playersPath,
    element: GamePlayersPage,
    protected: true,
    title: 'Game Players',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: playerProfilePath,
    element: PlayerProfile,
    protected: true,
    title: 'Player Profiles',
    pathType: 0,
    redirect: signInPath,
  },
]
