import { signInPath, starterPackPath } from 'logic/paths'
import React from 'react'

const StarterPackPage = React.lazy(() =>
  import('modules/pages/starterPack/StarterPackPage').then((module) => ({
    default: module.StarterPackPage,
  }))
)
export const starterPackAdminRoutes = [
  {
    path: starterPackPath,
    element: StarterPackPage,
    protected: true,
    title: 'Starter Pack page',
    pathType: 0,
    redirect: signInPath,
  },
]
