import * as React from 'react'

import { Sidebar } from 'modules/app/sidebar'
import { AdminDashHeader } from 'shared/headers'
import { CustomToaster } from 'shared/toaster/CustomToaster'
import { RouteDefinition } from 'utils/type'
import { Body, Main, Section, Wrapper } from './style'

export function withHeader(props: RouteDefinition) {
  const {
    element: InnerComponent,
    isHideNavbar,
    isDisablePadding,
    isHideSidebar,
  } = props
  return (props: any) => (
    <React.Fragment>
      <Section isSecondaryNav={isHideNavbar}>
        <Wrapper>
          {!isHideSidebar && <Sidebar className='publisherDesktopSidebar' />}
          <Body isDisablePadding={isDisablePadding}>
            {!isHideNavbar && <AdminDashHeader />}
            <Main>
              <InnerComponent {...props} />
            </Main>
          </Body>
        </Wrapper>
      </Section>
      <CustomToaster />
    </React.Fragment>
  )
}
