import {
  signInPath,
  userCustomReportTablePath,
  userReportCreateFilterPath,
  userReportEditFilterPath,
  userReportFiltersPath,
  userReportPath,
  userReportTablePath,
} from 'logic/paths'
import React from 'react'

const UserReportPage = React.lazy(() =>
  import('modules/pages/userReport/UserReport').then((module) => ({
    default: module.UserReport,
  }))
)

const UserReportTable = React.lazy(() =>
  import('modules/pages/userReport/UserReportTable').then((module) => ({
    default: module.UserReport,
  }))
)
const RenderReportFilters = React.lazy(() =>
  import('modules/pages/userReport/RenderReportFilters').then((module) => ({
    default: module.RenderReportFilters,
  }))
)

const ActivityReportPage = React.lazy(() =>
  import('modules/pages/activityReport/ActivityReport').then((module) => ({
    default: module.ActivityReport,
  }))
)

const commonRoutes = [
  {
    path: userReportPath,
    element: UserReportTable,
    protected: true,
    title: 'User Report Page',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: userReportFiltersPath,
    element: UserReportPage,
    protected: true,
    title: 'User Report Page',
    pathType: 0,
    redirect: signInPath,
  },

  {
    path: userReportTablePath,
    element: UserReportTable,
    protected: true,
    title: 'User Report Page',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: userCustomReportTablePath,
    element: UserReportTable,
    protected: true,
    title: 'User Report Page',
    pathType: 0,
    redirect: signInPath,
  },
  {
    path: userReportEditFilterPath,
    element: RenderReportFilters,
    protected: true,
    title: 'User Report Filters',
    pathType: 0,
    redirect: signInPath,
  },

  {
    path: userReportCreateFilterPath,
    element: RenderReportFilters,
    protected: true,
    title: 'User Report Filters',
    pathType: 0,
    redirect: signInPath,
  },
]

export const userReportAdminRoutes = [...commonRoutes]
