import jwtDecode from 'jwt-decode';

export const isJwtExpired = (token: string): boolean => {
    if (typeof token !== 'string' || !token) {
        return true;
    }

    // @ts-ignore
    const { exp } = jwtDecode(token);
    const currentTime = new Date().getTime() / 1000;

    if (currentTime > exp) {
        return true;
    }

    return false;
};
