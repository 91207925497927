import { publisherReportsPath, signInPath } from 'logic/paths'
import React from 'react'

const ReportsConfigurationPublisher = React.lazy(() =>
  import('modules/pages/configurations/reports/Reports').then((module) => ({
    default: module.Reports,
  }))
)

export const publisherReportConfigurationRoutes = [
  {
    path: publisherReportsPath,
    element: ReportsConfigurationPublisher,
    protected: true,
    title: 'Reports configuration for publisher',
    pathType: 0,
    redirect: signInPath,
  },
]
