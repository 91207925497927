import { signInPath, subAdminPath } from 'logic/paths'
import React from 'react'

const SubAdminPage = React.lazy(() =>
  import('modules/pages/subAdmin/SubAdminPage').then((module) => ({
    default: module.SubAdminPage,
  }))
)
export const subAdminRoutes = [
  {
    path: subAdminPath,
    element: SubAdminPage,
    protected: true,
    title: 'Sub admin page',
    pathType: 0,
    redirect: signInPath,
  },
]
