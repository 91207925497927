import { rgba } from 'polished'
import styled, { css, keyframes } from 'styled-components'
import { screenSizes } from 'styles/theme'

const animateLoader = keyframes`
  from {transform : rotate(0deg)}
  to {transform : rotate(360deg)}
`

// here in the svg you can add the svg according and can animate
const svgCSS = css`
  font-size: 26px;
  animation: ${animateLoader} linear 2s infinite;
`
const buttonStyles = css<I_ButtonWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  min-height: ${(props: any) => (props.noMinHeight ? 'none' : '40px')};
  min-width: ${(props: any) => (props.noMinWidth ? 'none' : '120px')};
  border-radius: 50px;
  cursor: pointer;
  transition: all linear 0.3s;
  border: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  :disabled {
    opacity: 0.5;
    filter: none !important;
    cursor: default;
  }
  white-space: nowrap;
  border: 1px solid transparent;
  font-size: ${(props) => props.fSize || '14px'};
  line-height: ${(props) => props.lineHeight || '21px'};
  ${({ disableCircularLoadAnimation }) =>
    !disableCircularLoadAnimation &&
    css`
      svg {
        ${svgCSS}
      }
    `}
  :disabled {
    ::after {
      display: none !important;
    }
    pointer-events: none !important;
  }
`
const primaryHoverEffect = css`
  z-index: 0;
  overflow: hidden;
  position: relative;

  ::after {
    background-color: ${(props) => props.theme.secondary};
    border-radius: 3rem;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: 0.4s transform ease-out;
    will-change: transform;
    z-index: -1;
  }
  :hover::after {
    transform: translate(0, 0);
  }
  :hover {
    color: ${(props) => props.theme.primary};
    box-shadow: 0px 15px 25px -5px #9163cc3b;
    transform: scale(1.01);
    will-change: transform;
  }
  :active {
    box-shadow: 0px 4px 8px ${(props) => props.theme.primary};
    transform: scale(0.98);
  }
`
const secondaryHoverEffect = css`
  ${primaryHoverEffect};
  ::after {
    background-color: ${(props) => props.theme.primary};
  }
  :hover {
    color: ${(props) => props.theme.secondary};
  }
`

const primary = css`
  background-color: ${(props: any) => props.theme.primary};
  color: ${(props: any) => props.theme.secondary};

  ${primaryHoverEffect};

  :disabled {
    opacity: 0.5;
    background-color: ${(props: any) => props.theme.primary};
    color: ${(props: any) => props.theme.secondary};
    filter: none !important;
    cursor: default;
  }
`
const primaryGradient = css`
  background: ${(props: any) => props.theme.primaryGradient};
  color: ${(props: any) => props.theme.secondary};
  :hover,
  :focus {
    border: 1px solid ${(props: any) => props.theme.hyperLink} !important;
    background-color: ${(props: any) => props.theme.secondary};
    color: ${(props: any) => props.theme.hyperLink};
    filter: drop-shadow(0px 4px 180px ${(props: any) => props.theme.primary});
  }
  :disabled {
    opacity: 0.5;
  }
`

const primaryS = css`
  ${primary};
  padding: 0;
  min-height: 30px;
  min-width: 50px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
`

const primaryF = css`
  ${primary};
  padding: 5px 16px;
  min-height: 0;
  min-width: 110px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
`
const primaryNav = css`
  ${primary};
  @media (max-width: ${screenSizes.mediaCL}px) {
    min-width: 80px;
    width: 80px;
  }
`

const primary2 = css`
  ${primary};
  transition: 300ms linear;
  ::after {
    background-color: ${(props) => props.theme.footer};
  }
  :hover {
    color: ${(props) => props.theme.secondary};
  }
`

const secondaryNav = css`
  ${primaryNav};
  border: 1px solid ${(props: any) => props.theme.borderColor};
`
const secondary = css`
  background-color: ${(props: any) => props.theme.secondary};
  color: ${(props: any) => props.theme.primary};
  border: 1px solid ${(props: any) => props.theme.primary};
  ${secondaryHoverEffect};

  :disabled {
    opacity: 0.5;
    background-color: ${(props: any) => props.theme.secondary};
    color: ${(props: any) => props.theme.primary};
    filter: none !important;
    cursor: default;
  }
`

const secondaryS = css`
  ${secondary};
  min-height: 32px;
  min-width: 76px;
`
const deleteS = css`
  ${secondaryS};
  color: ${(props) => props.theme.error};
  border-color: ${(props) => props.theme.error};
  ${primaryHoverEffect};
  ::after {
    background-color: ${(props) => props.theme.error};
  }
  :hover {
    color: ${(props) => props.theme.secondary};
  }
`
const primaryRed = css`
  ${primary};
  background-color: ${(props: any) => props.theme.error};
  color: ${(props: any) => props.theme.secondary};
  transition: 300ms linear;
  border: 1px solid ${(props) => props.theme.error};

  :hover {
    color: ${(props) => props.theme.error};
  }
`
const seconday2 = css`
  ${secondary};
  background: ${(props: any) => rgba(231, 231, 231, 0.36)};
`
const secondaryIconBtn = css`
  ${secondary};
  min-width: 44px;
  min-height: 44px;
  border-radius: 50%;
  padding: 0;
`
const textBtn = css`
  ${secondary};
  min-width: 44px;
  min-height: 44px;
  padding: 0;
  background-color: transparent;
  border: none !important;
`
const textBtnDanger = css`
  ${deleteS};
  min-width: 44px;
  min-height: 44px;
  padding: 0;
  border: none !important;
`

const primaryScalable = css`
  ${primary};
  @media (max-width: ${screenSizes.mediaSS}px) {
    min-width: 100px;
    width: 100px;
    font-size: 12px;
  }
`

const secondaryScalable = css`
  ${secondary};
  @media (max-width: ${screenSizes.mediaSS}px) {
    min-width: 100px;
    width: 100px;
    font-size: 12px;
  }
`

const rounded = css`
  background-color: ${(props: any) => props.theme.primary};
  color: ${(props: any) => props.theme.secondary};
  :hover,
  :focus {
    border: 1px solid ${(props: any) => props.theme.hyperLink} !important;
    background-color: ${(props: any) => props.theme.secondary};
    color: ${(props: any) => props.theme.hyperLink};
    filter: drop-shadow(0px 4px 100px ${(props: any) => props.theme.primary});
  }
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  width: 100%;
`

const hyperText = css`
  background-color: ${(props: any) => props.theme.hyperLink};
  color: ${(props: any) => props.theme.secondary};
  :hover,
  :focus {
    border: 1px solid ${(props: any) => props.theme.primary} !important;
    background-color: ${(props: any) => props.theme.secondary};
    color: ${(props: any) => props.theme.primary};
    filter: drop-shadow(0px 4px 34px ${(props: any) => props.theme.hyperLink});
  }
`

const roundedExpandable = css`
  width: 100%;
  min-width: 195px;
  border-radius: 50px;
  border: none;
  border-left: 1px solid #ffffff;
  background-color: ${(props: any) => props.theme.primary};
  color: ${(props: any) => props.theme.secondary};
  :hover,
  :focus {
    background-color: ${(props: any) => props.theme.primary};
    color: ${(props: any) => props.theme.secondary};
  }
  padding: 0 22px;
  display: flex;
  align-items: center;
`
const roundedCollapsible = css`
  background-color: ${(props: any) => props.theme.primary};
  color: ${(props: any) => props.theme.secondary};
  border: 1px solid ${(props: any) => props.theme.primary};
  min-width: 195px;
  padding: 0 22px;
  :hover,
  :focus {
    background-color: ${(props: any) => props.theme.primary};
    color: ${(props: any) => props.theme.secondary};
  }
`

const buttonWrapperStyles: any = {
  primaryGradient,
  primary,
  secondary,
  roundedCollapsible,
  roundedExpandable,
  hyperText,
  rounded,
  primaryS,
  deleteS,
  primaryNav,
  secondaryNav,
  primaryScalable,
  secondaryScalable,
  primaryF,
  primary2,
  seconday2,
  secondaryS,
  secondaryIconBtn,
  textBtn,
  textBtnDanger,
  primaryRed,
}

interface I_ButtonWrapperProps {
  btnType?: string
  fSize?: string
  fSizeMobile?: string
  disabled?: boolean
  width?: string
  onClick?: Function
  height?: string
  disableCircularLoadAnimation?: boolean
  noMinHeight?: boolean
  noMinWidth?: boolean
  padding?: string
  lineHeight?: string
}

export const ButtonWrapper = styled.button<I_ButtonWrapperProps>`
  ${buttonStyles}
  ${(props: I_ButtonWrapperProps) =>
    buttonWrapperStyles[props.btnType || 'primary']};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
`

interface I_ButtonAlignmentProps {
  align?: string
  justify?: string
  width?: string
}

export const ButtonAlignment = styled.div<I_ButtonAlignmentProps>`
  display: flex;
  align-items: ${(props: any) =>
    (props.align === 'center' && 'center') ||
    (props.align === 'start' && 'flex-start') ||
    (props.align === 'end' && 'flex-end')};
  justify-content: ${(props: any) => props.justify || 'center'};
  width: ${(props) => props.width};
`
